import React, { useEffect, useState } from 'react';

import {
  Button,
  Container, Grid, Paper, Typography,
} from '@material-ui/core';

import {
  useHistory,
} from 'react-router-dom';

import useStyles from './styles';
import { useStore } from '../../store';
import { getDashData } from '../../services';

export function Dash() {
  const Styles = useStyles();

  const history = useHistory();

  const {
    config,
  } = useStore();

  const [opened, setOpened] = useState(0);
  const [contacted, setContacted] = useState(0);
  const [finished, setFinished] = useState(0);
  const [canceled, setCanceled] = useState(0);

  const loadInstallationsDashData = async () => {
    const { success, message, result } = await getDashData();

    setOpened(result.opened);
    setContacted(result.contacted);
    setFinished(result.finished);
    setCanceled(result.canceled);
  };

  useEffect(() => {
    loadInstallationsDashData();

    config.loadConfig();
  }, []);

  return (
    <Container className={ Styles.container }>
      <Grid
        container
      >
        <Grid item sm={ 12 } md={ 6 } lg={ 4 }>
          <Paper className={`${ Styles.card } ${ Styles.cardPrimary }`}>
            <Typography className={Styles.cardTitle}>
              Solicitações de Instalação
            </Typography>

            <div className={Styles.cardInfoView}>
              <div className={Styles.cardInfo}>
                Pendente

                <span>{opened}</span>
              </div>

              <div className={Styles.cardInfo}>
                Contato iniciado

                <span>{contacted}</span>
              </div>

              <div className={Styles.cardInfo}>
                Finalizados

                <span>{finished}</span>
              </div>

              <div className={Styles.cardInfo}>
                Cancelados

                <span>{canceled}</span>
              </div>
            </div>

            <Button
              variant="contained"
              color="secondary"
              onClick={() => history.push('/installations')}
            >
              Ver Mais
            </Button>
          </Paper>
        </Grid>

        <Grid item sm={ 12 } md={ 6 } lg={ 4 }>
          <Paper className={`${ Styles.card } ${ Styles.cardPrimary }`}>
            <Typography className={Styles.cardTitle}>
              Visitas
            </Typography>

            <div className={Styles.cardInfoView}>
              <div className={Styles.cardInfo}>
                Hoje

                <span>{opened}</span>
              </div>

              <div className={Styles.cardInfo}>
                Últimos 7 dias

                <span>{contacted}</span>
              </div>

              <div className={Styles.cardInfo}>
                Últimos 30 dias

                <span>{finished}</span>
              </div>

              <div className={Styles.cardInfo}>
                Total

                <span>{canceled}</span>
              </div>
            </div>

            <Button
              variant="contained"
              color="secondary"
              onClick={() => history.push('/installations')}
            >
              Ver Mais
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
