export const cityColumns = [
  {
    key: 'name',
    label: 'Nome',
    align: 'left',
    span: 1,
    width: '25%',
    collapse: false,
  },

  {
    key: 'state',
    label: 'Estado',
    align: 'left',
    span: 1,
    width: '15%',
    collapse: false,
  },

  {
    key: 'phone',
    label: 'Telefone',
    align: 'left',
    span: 1,
    width: '15%',
    collapse: false,
  },

  {
    key: 'email',
    label: 'Email',
    align: 'left',
    span: 1,
    width: '20%',
    collapse: false,
  },

  {
    key: 'whatsapp',
    label: 'Whatsapp',
    align: 'left',
    span: 1,
    width: '15%',
    collapse: false,
  },

  {
    key: 'img',
    label: 'Background',
    align: 'center',
    span: 1,
    width: '5%',
    collapse: false,
  },
];

export const planColumns = [
  {
    key: 'name',
    label: 'Nome',
    align: 'left',
    span: 1,
    width: '30%',
    collapse: false,
  },

  {
    key: 'type',
    label: 'Tipo',
    align: 'left',
    span: 1,
    width: '25%',
    collapse: false,
  },

  {
    key: 'value',
    label: 'Valor',
    align: 'left',
    span: 1,
    width: '15%',
    collapse: false,
  },

  {
    key: 'cities_names',
    label: 'Cidades',
    align: 'left',
    span: 1,
    width: '10%',
    collapse: true,
  },

  {
    key: 'benefits_names',
    label: 'Benefícios',
    align: 'left',
    span: 1,
    width: '5%',
    collapse: true,
  },

  {
    key: 'servicesIncluded_names',
    label: 'Serviços Inclusos',
    align: 'left',
    span: 1,
    width: '5%',
    collapse: true,
  },

  {
    key: 'description',
    label: 'Descrição',
    align: 'left',
    span: 1,
    width: '10%',
    collapse: true,
  },
];

export const installationColumns = [
  {
    key: 'name',
    label: 'Nome',
    align: 'left',
    span: 1,
    width: '35%',
    collapse: false,
  },

  {
    key: '_whatsapp',
    label: 'Whatsapp',
    align: 'left',
    span: 1,
    width: '15%',
    collapse: false,
  },

  {
    key: '_email',
    label: 'Email',
    align: 'left',
    span: 1,
    width: '20%',
    collapse: false,
  },

  {
    key: 'plaName',
    label: 'Plano',
    align: 'left',
    span: 1,
    width: '15%',
    collapse: false,
  },

  {
    key: '_status',
    label: 'Status',
    align: 'center',
    span: 1,
    width: '15%',
    collapse: false,
  },
];

export const visitColumns = [
  {
    key: 'ipAddress',
    label: 'Endereço IP',
    align: 'center',
    span: 1,
    width: '35%',
    collapse: false,
  },

  {
    key: 'visits',
    label: 'Visitas',
    align: 'center',
    span: 1,
    width: '15%',
    collapse: false,
  },

  {
    key: '_firstVisit',
    label: 'Primeira Visita',
    align: 'center',
    span: 1,
    width: '20%',
    collapse: false,
  },

  {
    key: '_lastVisit',
    label: 'Última Visita',
    align: 'center',
    span: 1,
    width: '20%',
    collapse: false,
  },
];

export const userColumns = [
  {
    key: 'name',
    label: 'Nome',
    align: 'left',
    span: 1,
    width: '40%',
    collapse: false,
  },

  {
    key: 'username',
    label: 'Usuário',
    align: 'left',
    span: 1,
    width: '35%',
    collapse: false,
  },

  {
    key: 'typeName',
    label: 'Tipo',
    align: 'left',
    span: 1,
    width: '10%',
    collapse: false,
  },

  {
    key: 'activeButtonIcon',
    label: 'Status',
    align: 'center',
    span: 1,
    width: '10%',
    collapse: false,
  },
];

export const postColumns = [
  {
    key: 'img',
    label: 'Imagem',
    align: 'center',
    span: 1,
    width: '40%',
    collapse: false,
  },

  {
    key: 'name',
    label: 'Nome',
    align: 'left',
    span: 1,
    width: '55%',
    collapse: false,
  },
];

export const benefitColumns = [
  {
    key: 'name',
    label: 'Nome',
    align: 'left',
    span: 1,
    width: '30%',
    collapse: false,
  },

  {
    key: 'description',
    label: 'Descrição',
    align: 'left',
    span: 1,
    width: '40%',
    collapse: false,
  },

  {
    key: 'radio',
    label: 'Rádio',
    align: 'center',
    span: 1,
    width: '15%',
    collapse: false,
  },

  {
    key: 'fiber',
    label: 'Fibra Óptica',
    align: 'center',
    span: 1,
    width: '15%',
    collapse: false,
  },
];

export const serviceColumns = [
  {
    key: '_logo',
    label: 'Logo',
    align: 'center',
    span: 1,
    width: '10%',
    collapse: false,
  },

  {
    key: 'name',
    label: 'Nome',
    align: 'left',
    span: 1,
    width: '40%',
    collapse: false,
  },

  {
    key: 'description',
    label: 'Descrição',
    align: 'left',
    span: 1,
    width: '50%',
    collapse: false,
  },
];
