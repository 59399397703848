import React, {
  useEffect,
} from 'react';

import {
  useLocation,
} from 'react-router-dom';

import {
  useStore,
} from '../../store';

import {
  planColumns,
} from '../../utils/columns';

import {
  DataTable,
} from '../../components';

import useStyles from './styles';

export function Plan() {
  const Styles = useStyles();
  const location = useLocation();

  const {
    plan,
  } = useStore();

  useEffect(() => {
    if( location.pathname === '/plans')
      plan.loadPlans();

  }, [ location ]);

  return (
    <DataTable
      title="Planos"
      columns={ planColumns }
      store={ plan }
    />
  );
};
