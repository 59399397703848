import { useState } from 'react';
import useStyles from './styles';
import { Button, IconButton, Paper, Popover } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { useStore } from '../../store';
import { updateStatusInstallation } from '../../services';

const buttons = [
  {
    label: 'Aberto',
    value: 'opened'
  },

  {
    label: 'Em Contato',
    value: 'contacted'
  },

  {
    label: 'Finalizado',
    value: 'finished'
  },

  {
    label: 'Cancelado',
    value: 'canceled'
  },
];

export const InstallationStatus = ({id, status}) => {
  const Styles = useStyles({status});

  const {
    alert,
    installation,
    config,
  } = useStore();

  const [ anchorEl, setAnchorEl ] = useState( null );

  const getLabel = () => {
    switch (status) {
      case 'opened':
        return 'Aberto';
      case 'contacted':
        return 'Em Contato';
      case 'finished':
        return 'Finalizado';
      case 'canceled':
        return 'Cancelado';
      default:
        return 'Aberto';
    }
  };

  const getColor = (status) => {
    switch (status) {
      case 'opened':
        return '#FB6219';
      case 'contacted':
        return '#28A745';
      case 'finished':
        return '#2664C4';
      case 'canceled':
        return '#FF3030';
      default:
        return '#FB6219';
    }
  };

  const handleChangeStatus = async (status) => {
    const { success, message } = await updateStatusInstallation(id, status);

    alert.openAlert( success, message );

    setAnchorEl( null );

    if(success) installation.loadInstallations(config.data);
  };

  return (
    <>
      <div
        className={Styles.statusView}
        style={{
          backgroundColor: getColor(status),
        }}
        onClick={ event => {
          if( id ) {
            event.stopPropagation();
            setAnchorEl( event.currentTarget );
          }
        }}
      >
        { getLabel()}
      </div>

      <Popover
        open={ Boolean( anchorEl )}
        anchorEl={ anchorEl }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl( null )}
        disableRestoreFocus
      >
        <Paper className={ Styles.popover }>
          <IconButton
            color="primary"
            size="small"
            className={ Styles.close }
            onClick={() => setAnchorEl( null )}
          >
            <CloseRounded />
          </IconButton>

          <span>Alterar Status</span>

          {
            buttons.map((button) => (
              <Button
                key={button.value}
                className={`${ status ? Styles.inactive : Styles.active }`}
                variant="text"
                style={{
                  color: getColor(button.value),
                }}
                onClick={() => handleChangeStatus(button.value)}
              >
                {button.label}
              </Button>
            ))
          }
        </Paper>
      </Popover>
    </>
  );
};
