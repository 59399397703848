import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({
  icon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  active: {
    cursor: 'pointer',
    fontWeight: 700,
    color: '#28A745'
  },

  inactive: {
    cursor: 'pointer',
    fontWeight: 700,
    color: '#FF3030',
  },

  popover: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 200,
    height: 125,
    padding: 15,
    paddingTop: 45,

    '& span': {
      textAlign: 'center',
    },
  },

  close: {
    position: 'absolute',
    top: 5,
    left: 5,
  },
}));

export default useStyles;
