import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  Button,
  TextField,
  Modal,
  Paper,
  Toolbar,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import {
  useStore,
} from '../../store';

import {
  LoadingButton,
} from '../LoadingButton';

import useStyles from './styles';

export function ModalBenefit() {
  const Styles = useStyles();

  const {
    alert,
    benefit,
    city,
  } = useStore();

  const [ name, setName ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ radio, setRadio ] = useState( false );
  const [ fiber, setFiber ] = useState( true );

  const handleSave = useCallback( async () => {
    const { success, message } = await benefit.handleSave( name, description, radio, fiber );

    alert.openAlert( success, message );

    if( success )
      benefit.handleCloseModal();
  }, [ name, description, radio, fiber ]);

  async function loadCities() {
    await city.loadCities();
  };

  useEffect(() => {
    if( !benefit.isOpenModal ) {
      setName('');
      setDescription('');
      setRadio( false );
      setFiber( true );
    } else {
      if( city.data.length === 0 ) {
        loadCities();
      }

      if( benefit.benefit?._id ) {
        setName( benefit.benefit.name );
        setDescription( benefit.benefit.description );
        setRadio( benefit.benefit.radio.props.status );
        setFiber( benefit.benefit.fiber.props.status );
      }
    }
  }, [ benefit.isOpenModal, benefit.benefit ]);

  return (
    <Modal
      open={ benefit.isOpenModal }
      onClose={ benefit.handleCloseModal }
      className={ Styles.modal }
    >
      <Paper className={ Styles.modalView }>
        <Toolbar className={ Styles.modalToolbar }>
          <Typography
            noWrap
            className={ Styles.modalTitle }
            variant="h6"
          >
            {`${ benefit.benefit?.function || 'Adicionar'} Benefício`}
          </Typography>
        </Toolbar>

        <Container className={ Styles.bodyModal }>
          {
            benefit.benefit?.function === 'Excluir'
              ? <>
                  <Typography className={ Styles.question } component="h5">
                    Deseja realmente excluir o benefício
                  </Typography>

                  <Typography component="h5">
                    { benefit.benefit?.description }?
                  </Typography>
                </>
              : <>
                  <TextField
                    className={ Styles.input }
                    label="Nome"
                    placeholder="Nome"
                    variant="outlined"
                    value={ name }
                    onChange={ event => setName( event.target.value )}
                  />

                  <TextField
                    className={ Styles.input }
                    label="Descrição"
                    placeholder="Descrição"
                    variant="outlined"
                    value={ description }
                    onChange={ event => setDescription( event.target.value )}
                    multiline
                    maxRows={ 6 }
                  />

                  <span className={ Styles.checkboxLabel}>Instalação</span>

                  <div className={ Styles.checkboxView }>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ radio }
                          onChange={ event => setRadio( event.target.checked )}
                          color="primary"
                        />}
                      label="Rádio"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ fiber }
                          onChange={ event => setFiber( event.target.checked )}
                          color="primary"
                        />}
                      label="Fibra"
                    />
                  </div>
                </>
          }

          <Container className={ Styles.modalActions }>
            <Button
              variant="outlined"
              color="inherit"
              onClick={ benefit.handleCloseModal }
            >
              { benefit.benefit?.function === 'Excluir' ? 'Não' : 'Cancelar'}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={ handleSave }
              disabled={
                benefit.saving ||
                !name
              }
            >
              { benefit.benefit?.function === 'Excluir' ? 'Sim' : 'Salvar'}

              { benefit.saving && <LoadingButton />}
            </Button>
          </Container>
        </Container>
      </Paper>
    </Modal>
  );
};
