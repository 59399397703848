import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({
  container: {
    padding: 0,
  },

  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 15,
    width: '95%',
    marginBottom: 25,
    color: '#FFF',
  },

  cardPrimary: {
    backgroundColor: theme.palette.primary.main,
  },

  cardSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },

  cardTitle: {
    fontSize: 24,
    textAlign: 'center',
  },

  cardInfoView: {
    width: '100%',
    padding: 32,
    paddingBottom: 24,
  },

  cardInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 16,
    marginBottom: 8,

    '& span': {
      textAlign: 'right',
    }
  },
}));

export default useStyles;
