import api from '../config/api';

export async function getCities() {
  try {
    const { data } = await api.get('/city');

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};

export async function createCity( formData ) {
  try {
    const { data } = await api.post('/city', formData );

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};

export async function updateCity( id, formData ) {
  try {
    const { data } = await api.put(`/city/${ id }`, formData );

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};

export async function deleteCity( id ) {
  try {
    const { data } = await api.delete(`/city/${ id }`);

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};
