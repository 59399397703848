import React, {
  useEffect,
} from 'react';

import {
  useLocation,
} from 'react-router-dom';

import {
  useStore,
} from '../../store';

import {
  cityColumns,
} from '../../utils/columns';

import {
  DataTable,
} from '../../components';

import useStyles from './styles';

export function City() {
  const Styles = useStyles();
  const location = useLocation();

  const {
    city,
  } = useStore();

  useEffect(() => {
    if( location.pathname === '/cities');
      city.loadCities();

  }, [ location ]);

  return (
    <DataTable
      title="Cidades"
      columns={ cityColumns }
      store={ city }
    />
  );
};
