import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({
  tableView: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
  },

  head: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: 0,
    marginBottom: 15,
  },

  title: {
    fontSize: 22,
    fontWeight: 600,
  },

  delete: {
    color: theme.palette.error.main,
  },

  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 0,
    marginTop: 15,
  },

  icon: {
    transition: 'all .6s',
  },

  open: {
    transform: 'rotate( 180deg )',
  },

  collapse: {
    display: 'flex',
    flexDirection: 'row',
  },

  description: {
    width: '100%',
    whiteSpace: 'pre-wrap',
  },
}));

export default useStyles;
