import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';

import {
  Container,
  Paper,
  TextField,
  Button,
} from '@material-ui/core';

import api from '../../config/api';

import {
  useStore
} from '../../store';

import bg from '../../assets/bg.jpg';
import logo from '../../assets/logo.png';

import {
  LoadingButton,
} from '../../components';

import useStyles from './styles';

export function Login({ history }) {
  const {
    alert,
    auth,
  } = useStore();

  const [ config, setConfig ] = useState( null );

  const Styles = useStyles();

  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');

  async function loadConfig() {
    try {
      const { data } = await api.get('/site/config');

      const {
        success,
        result,
      } = data;

      if( success ) {
        setConfig( result );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = useCallback( async () => {
    const { success, message } = await auth.logIn( username, password );

    if( success )
      history.push('/dash');

    alert.openAlert( success, message );
  }, [ username, password ]);

  useEffect(() => {
    loadConfig();
  }, []);

  return (
    <Container
      className={ Styles.container }
      maxWidth={ false }
      style={{
        backgroundImage: config?.background ? `url(${ process.env.REACT_APP_API_URL }/image/${ config?.background })` : 'none',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'border-box',
      }}
    >
      <div className={ Styles.blur }>
        <Paper className={ Styles.paper }>
          <img
            src={ logo }
            alt="InfoTurbo Telecom"
            className={ Styles.logo }
            id="logo"
          />

          <TextField
            className={ Styles.input }
            placeholder="Usuário"
            label="Usuário"
            variant="outlined"
            color="primary"
            value={ username }
            onChange={ event => setUsername( event.target.value )}
            onKeyDown={ event => {
              if( event.key === 'Enter') handleSubmit();
            }}
          />

          <TextField
            className={ Styles.input }
            placeholder="Senha"
            label="Senha"
            variant="outlined"
            color="primary"
            value={ password }
            onChange={ event => setPassword( event.target.value )}
            onKeyDown={ event => {
              if( event.key === 'Enter') handleSubmit();
            }}
            type="password"
          />

          <Button
            className={ Styles.button }
            variant="contained"
            color="primary"
            size="large"
            disabled={ auth.loading }
            onClick={ handleSubmit }
          >
            Entrar

            { auth.loading && <LoadingButton />}
          </Button>
        </Paper>
      </div>
    </Container>
  );
};
