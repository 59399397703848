import useStyles from "./styles";

export const Switch = ({
  label,
  value,
  onChange,
  disabled,
}) => {
  const Styles = useStyles();

  return (
    <div className={Styles.SwitchView}>
      <span className={Styles.SwitchLabel}>{label}</span>

      <button onClick={() => !disabled ? onChange(!value) : {}} style={{
        backgroundColor: disabled ? '#eeeeee' : value ? '#2664C422' : '#e0e0e0',
        flexDirection: value ? 'row-reverse' : 'row',
        cursor: disabled ? 'default' : 'pointer',
      }} className={Styles.SwitchButton}>
        <div style={{backgroundColor: disabled ? '#e0e0e0' : '#2664C4'}} className={Styles.SwitchCircle} />
      </button>

      {disabled && <span className={Styles.exist}>Já existe outro plano marcado como mais vendido</span>}
    </div>
  );
};
