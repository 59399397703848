import api from '../config/api';

export async function getServices() {
  try {
    const { data } = await api.get('/service');

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};

export async function createService( formData ) {
  try {
    const { data } = await api.post('/service', formData);

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};

export async function updateService( id, formData ) {
  try {
    const { data } = await api.put(`/service/${ id }`, formData);

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};

export async function deleteService( id ) {
  try {
    const { data } = await api.delete(`/service/${ id }`);

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};
