import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  Button,
  TextField,
  Modal,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';

import InputMask from "react-input-mask";

import {
  Autocomplete,
} from '@material-ui/lab';

import {
  useStore,
} from '../../store';

import {
  LoadingButton,
} from '../LoadingButton';

import useStyles from './styles';

export function ModalInstallation() {
  const Styles = useStyles();

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const {
    alert,
    installation,
    plan,
    config,
  } = useStore();

  const [ name, setName ] = useState('');
  const [ whatsapp, setWhatsapp ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ selectedPlan, setSelectedPlan ] = useState(null);

  const handleSave = useCallback( async () => {
    const { success, message } = await installation.handleSave( name, whatsapp, email, selectedPlan?._id, config );

    alert.openAlert( success, message );

    if( success )
      installation.handleCloseModal();
  }, [ name, whatsapp, email, selectedPlan?._id ]);

  async function loadPlans() {
    await plan.loadPlans();
  };

  useEffect(() => {
    if( !installation.isOpenModal ) {
      setName('');
      setWhatsapp('');
      setEmail('');
      setSelectedPlan(null);
    } else {
      if( plan.data.length === 0 ) {
        loadPlans();
      }

      if( installation.installation?._id ) {
        setName( installation.installation.name );
        setWhatsapp( installation.installation.whatsapp );
        setEmail( installation.installation.email );
        setSelectedPlan( installation.installation.plan );
      }
    }
  }, [ installation.isOpenModal, installation.installation ]);

  return (
    <Modal
      open={ installation.isOpenModal }
      onClose={ installation.handleCloseModal }
      className={ Styles.modal }
    >
      <Paper className={ Styles.modalView }>
        <Toolbar className={ Styles.modalToolbar }>
          <Typography
            noWrap
            className={ Styles.modalTitle }
            variant="h6"
          >
            {`${ installation.installation?.function || 'Adicionar'} Solicitação`}
          </Typography>
        </Toolbar>

        <Container className={ Styles.bodyModal }>
          {
            installation.installation?.function === 'Excluir'
              ? <>
                  <Typography className={ Styles.question } component="h5">
                    Deseja realmente excluir a solicitação de
                  </Typography>

                  <Typography component="h5">
                    { installation.installation?.name }?
                  </Typography>
                </>
              : <>
                  <TextField
                    className={ Styles.input }
                    label="Nome"
                    placeholder="Nome"
                    variant="outlined"
                    value={ name }
                    onChange={ event => setName( event.target.value )}
                  />

                  <InputMask
                    mask="(99) 9 9999-9999"
                    value={ whatsapp }
                    onChange={ event => setWhatsapp( event.target.value )}
                  >
                    {() =>
                      <TextField
                        className={ Styles.input }
                        label="Whatsapp"
                        placeholder="Whatsapp"
                        variant="outlined"
                        type="cel-phone"
                      />
                    }
                  </InputMask>

                  <TextField
                    className={ Styles.input }
                    label="Email"
                    placeholder="Email"
                    variant="outlined"
                    value={ email }
                    onChange={ event => setEmail( event.target.value )}
                  />

                  <Autocomplete
                    className={ Styles.input }
                    options={ plan.data }
                    getOptionLabel={( option ) => option.name }
                    getOptionSelected={( option ) => option._id === selectedPlan._id }
                    noOptionsText="Empty..."
                    value={ selectedPlan }
                    onChange={( event, newValue ) => {
                      setSelectedPlan( newValue );
                    }}
                    renderInput={( params ) => <Container className={ Styles.autoField }>
                      <TextField
                        { ...params }
                        label="Plano"
                        variant="outlined"
                      />
                    </Container>}
                  />
                </>
          }

          <Container className={ Styles.modalActions }>
            <Button
              variant="outlined"
              color="inherit"
              onClick={ installation.handleCloseModal }
            >
              { installation.installation?.function === 'Excluir' ? 'Não' : 'Cancelar'}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={ handleSave }
              disabled={
                installation.saving ||
                !name ||
                !whatsapp ||
                !plan ||
                plan === 'Selecione'
              }
            >
              { installation.installation?.function === 'Excluir' ? 'Sim' : 'Salvar'}

              { installation.saving && <LoadingButton />}
            </Button>
          </Container>
        </Container>
      </Paper>
    </Modal>
  );
};
