import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 0,
  },

  paper: {
    position: 'relative',
    width: '75%',
    minWidth: 305,
    maxWidth: 545,
    padding: 15,
  },

  title: {
    fontSize: 22,
    fontWeight: 600,
  },

  input: {
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },

  instructions: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    padding: 8,
    paddingTop: 0,
    paddingBottom: 24,
  },

  instructionsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& span': {
      flex: 1,
    },
  },

  backView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: 25,

    '& span': {
      fontSize: 16,
      fontWeight: 500,
      marginBottom: 15,
    }
  },

  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    marginTop: 15,
  },
}));

export default useStyles;
