import {
  useState,
  useCallback,
  useMemo,
} from 'react';

import {
  getPlans,
  createPlan,
  updatePlan,
  deletePlan,
} from '../../services';

import {
  planTransform,
} from '../../utils/helpers/transformData';

export function usePlan() {
  const [ getting, setGetting ] = useState( false );
  const [ saving, setSaving ] = useState( false );
  const [ isOpenModal, setIsOpenModal ] = useState( false );
  const [ isDisabledBestSeller, setIsDisabledBestSeller ] = useState( false );

  const [ data, setData ] = useState([]);
  const [ plan, setPlan ] = useState( null );

  const loadPlans = useCallback( async () => {
    setGetting( true );

    const { success, message, result } = await getPlans();

    if( success ) {
      setData( planTransform( result ));
      setIsDisabledBestSeller( result.some(( item ) => item.bestSeller));
    }

    setGetting( false );

    return { success, message };
  }, []);

  const handleOpenModal = useCallback(( data ) => {
    setPlan( data );

    setIsOpenModal( true );
  }, []);

  const handleCloseModal = useCallback(() => {
    setPlan( null );

    setIsOpenModal( false );
  }, []);

  const handleSave = useCallback( async ( name, description, type, value, bestSeller, cities, benefit, servicesIncluded ) => {
    setSaving( true );

    const { success, message } = plan?._id
      ? plan?.function === 'Excluir'
        ? await deletePlan( plan._id )
        : await updatePlan( plan._id, name, description, type, value, bestSeller, cities, benefit, servicesIncluded )
      : await createPlan( name, description, type, value, bestSeller, cities, benefit, servicesIncluded );

    setSaving( false );

    if( success ) loadPlans();

    return { success, message };
  }, [ plan ]);

  return useMemo(() => ({
    data,
    plan,
    isDisabledBestSeller,
    getting,
    saving,
    isOpenModal,
    loadPlans,
    handleOpenModal,
    handleCloseModal,
    handleSave,
  }), [
    data,
    plan,
    isDisabledBestSeller,
    getting,
    saving,
    isOpenModal,
    loadPlans,
    handleOpenModal,
    handleCloseModal,
    handleSave,
  ]);
};
