import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  Button,
  TextField,
  Modal,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';

import InputMask from "react-input-mask";

import {
  Autocomplete,
} from '@material-ui/lab';

import states from '../../utils/estados.json';

import {
  useStore,
} from '../../store';

import {
  LoadingButton,
} from '../LoadingButton';

import useStyles from './styles';
import { DropzoneArea } from 'material-ui-dropzone';
import { Image } from '../Image';

export function ModalCity() {
  const Styles = useStyles();

  const {
    alert,
    city,
  } = useStore();

  const [ name, setName ] = useState('');
  const [ state, setState ] = useState('');
  const [ phone, setPhone ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ whatsapp, setWhatsapp ] = useState('');
  const [ oldBackground, setOldBackground ] = useState( null );
  const [ background, setBackground ] = useState( null );

  const handleSave = useCallback( async () => {
    const formData = new FormData();

    formData.append('name', name );
    formData.append('state', state );
    formData.append('phone', phone );
    formData.append('email', email );
    formData.append('whatsapp', whatsapp );
    formData.append('file', background );

    const { success, message } = await city.handleSave( formData );

    alert.openAlert( success, message );

    if( success)
      city.handleCloseModal();
  }, [ name, state, phone, email, whatsapp, background ]);

  useEffect(() => {
    if( !city.isOpenModal ) {
      setName('');
      setState('');
      setPhone('');
      setEmail('');
      setWhatsapp('');
      setOldBackground( null );
    } else {
      if( city.city ) {
        setName( city.city.name );
        setState( city.city.state );
        setPhone( city.city?.phone );
        setEmail( city.city?.email );
        setWhatsapp( city.city?.whatsapp );
        setOldBackground( city.city?.background );
      }
    }
  }, [ city.isOpenModal, city.city ]);

  return (
    <Modal
      open={ city.isOpenModal }
      onClose={ city.handleCloseModal }
      className={ Styles.modal }
    >
      <Paper className={ Styles.modalView }>
        <Toolbar className={ Styles.modalToolbar }>
          <Typography
            noWrap
            className={ Styles.modalTitle }
            variant="h6"
          >
            {`${ city.city?.function || 'Adicionar'} Cidade`}
          </Typography>
        </Toolbar>

        <Container className={ Styles.bodyModal }>
          {
            city.city?.function === 'Excluir'
              ? <>
                  <Typography className={ Styles.question } component="h5">
                    Deseja realmente excluir a cidade
                  </Typography>

                  <Typography component="h5">
                    { city.city?.name }?
                  </Typography>
                </>
              : <>
                  <TextField
                    className={ Styles.input }
                    label="Cidade"
                    placeholder="Cidade"
                    variant="outlined"
                    value={ name }
                    onChange={ event => setName( event.target.value )}
                  />

                  <Autocomplete
                    className={ Styles.input }
                    options={ states.map( item => item.name )}
                    getOptionLabel={( option ) => option }
                    getOptionSelected={( option ) => option === state }
                    noOptionsText="Empty..."
                    value={ state }
                    onChange={( event, newValue ) => {
                      setState( newValue );
                    }}
                    renderInput={( params ) => <Container className={ Styles.autoField }>
                      <TextField
                        { ...params }
                        label="Estado"
                        variant="outlined"
                      />
                    </Container>}
                  />

                  <InputMask
                    mask="(99) 9 9999-9999"
                    value={ phone }
                    onChange={ event => setPhone( event.target.value )}
                  >
                    {() =>
                      <TextField
                        className={ Styles.input }
                        label="Telefone"
                        placeholder="Telefone"
                        variant="outlined"
                        type="cel-phone"
                      />
                    }
                  </InputMask>

                  <TextField
                    className={ Styles.input }
                    label="Email"
                    placeholder="Email"
                    variant="outlined"
                    value={ email }
                    onChange={ event => setEmail( event.target.value )}
                  />

                  <InputMask
                    mask="(99) 9 9999-9999"
                    value={ whatsapp }
                    onChange={ event => setWhatsapp( event.target.value )}
                  >
                    {() =>
                      <TextField
                      className={ Styles.input }
                        label="Whatsapp"
                        placeholder="Whatsapp"
                        variant="outlined"
                        type="cel-phone"
                      />
                    }
                  </InputMask>

                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    dropzoneText={"Escolha um background, clique ou arraste"}
                    dropzoneParagraphClass={ Styles.dropZoneText }
                    onChange={( files ) => setBackground( files[ 0 ])}
                    showPreviewsInDropzone={ true }
                    previewText="Imagem selecionada"
                    showAlerts={ false }
                    filesLimit={ 1 }
                    dropzoneClass={ Styles.dropZone }
                  />

                  {
                    oldBackground &&
                      <div className={ Styles.backView }>
                        <span>Background Atual:</span>

                        <Image
                          image={ oldBackground }
                        />
                      </div>
                  }


                </>
          }

          <Container className={ Styles.modalActions }>
            <Button
              variant="outlined"
              color="inherit"
              onClick={ city.handleCloseModal }
            >
              { city.city?.function === 'Excluir' ? 'Não' : 'Cancelar'}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={ handleSave }
              disabled={
                city.saving ||
                !name ||
                !state ||
                state === ''
              }
            >
              { city.city?.function === 'Excluir' ? 'Sim' : 'Salvar'}

              { city.saving && <LoadingButton />}
            </Button>
          </Container>
        </Container>
      </Paper>
    </Modal>
  );
};
