import api from "../config/api";

export async function getDashData() {
  try {
    const { data } = await api.get('/dash');

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};
