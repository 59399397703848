import React from 'react';
import ReactDOM from 'react-dom';

import './styles.css';

import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

serviceWorkerRegistration.unregister();
