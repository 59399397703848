import {
  useState,
  useCallback,
  useMemo,
} from 'react';

import {
  getInstallations,
  createInstallation,
  updateInstallation,
  deleteInstallation,
} from '../../services';

import {
  installationTransform,
} from '../../utils/helpers/transformData';

export function useInstallation() {
  const [ getting, setGetting ] = useState( false );
  const [ saving, setSaving ] = useState( false );
  const [ isOpenModal, setIsOpenModal ] = useState( false );

  const [ data, setData ] = useState([]);
  const [ installation, setInstallation ] = useState( null );

  const loadInstallations = useCallback( async (config) => {
    setGetting( true );

    const { success, message, result } = await getInstallations();

    if( success ) {
      setData( installationTransform( result, config ));
    }

    setGetting( false );

    return { success, message };
  }, []);

  const handleOpenModal = useCallback(( data ) => {
    setInstallation( data );

    setIsOpenModal( true );
  }, []);

  const handleCloseModal = useCallback(() => {
    setInstallation( null );

    setIsOpenModal( false );
  }, []);

  const handleSave = useCallback( async ( name, whatsapp, email, plan, config ) => {
    setSaving( true );

    const { success, message } = installation?._id
      ? installation?.function === 'Excluir'
        ? await deleteInstallation( installation._id )
        : await updateInstallation( installation._id, name, whatsapp, email, plan )
      : await createInstallation( name, whatsapp, email, plan );

    setSaving( false );

    if( success ) loadInstallations(config);

    return { success, message };
  }, [ installation ]);

  return useMemo(() => ({
    data,
    installation,
    getting,
    saving,
    isOpenModal,
    loadInstallations,
    handleOpenModal,
    handleCloseModal,
    handleSave,
  }), [
    data,
    installation,
    getting,
    saving,
    isOpenModal,
    loadInstallations,
    handleOpenModal,
    handleCloseModal,
    handleSave,
  ]);
};
