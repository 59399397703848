import React, {
  useState,
} from 'react';

import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Popover,
} from '@material-ui/core';

import {
  CheckCircleOutlineRounded,
  HighlightOffRounded,
  CloseRounded,
} from '@material-ui/icons';

import {
  useStore,
} from '../../store';

import useStyles from './styles';

export function Status ({ status, id }) {
  const Styles = useStyles();

  const {
    alert,
    user,
  } = useStore();

  const [ anchorEl, setAnchorEl ] = useState( null );

  async function handleChangeAccess() {
    setAnchorEl( null );

    const { success, message } = await user.handleChangeAccess( id );

    alert.openAlert( success, message );
  };

  return (
    <>
      <div className={ Styles.icon }>
        {
          status
            ? <div
                className={ Styles.active }
                onClick={ event => {
                  if( id ) {
                    event.stopPropagation();
                    setAnchorEl( event.currentTarget );
                  }
                }}
              >
                {
                  user.changingAccess === id
                    ? <CircularProgress
                        size={ 22 }
                        className={ Styles.active }
                      />
                    : <CheckCircleOutlineRounded />
                  }
              </div>
            : <div
                className={ Styles.inactive }
                onClick={ event => {
                  if( id ) {
                    event.stopPropagation();
                    setAnchorEl( event.currentTarget );
                  }
                }}
              >
                {
                  user.changingAccess === id
                    ? <CircularProgress
                        size={ 22 }
                        className={ Styles.inactive }
                      />
                    : <HighlightOffRounded />
                  }
              </div>
        }
      </div>

      <Popover
        open={ Boolean( anchorEl )}
        anchorEl={ anchorEl }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl( null )}
        disableRestoreFocus
      >
        <Paper className={ Styles.popover }>
          <IconButton
            color="primary"
            size="small"
            className={ Styles.close }
            onClick={() => setAnchorEl( null )}
          >
            <CloseRounded />
          </IconButton>

          <span className={`${ status ? Styles.active : Styles.inactive }`}>Usuário { status ? 'Desbloqueado' : 'Bloqueado'}!</span>

          <Button
            className={`${ status ? Styles.inactive : Styles.active }`}
            variant="text"
            onClick={ handleChangeAccess }
          >
            { status ? 'Bloquear' : 'Desbloaquear'}
          </Button>
        </Paper>
      </Popover>
    </>
  );
};
