import React from 'react';

import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import {
  StateProvider,
  useStore
} from './store';

import routerConfig from './config/routes';

import {
  Login,
} from './pages';

import {
  Layout,
  Alert,
} from './components';

export default function Routes() {
  return (
    <BrowserRouter>
      <StateProvider>
        <SwitchRouter />
      </StateProvider>
    </BrowserRouter>
  );
};

function SwitchRouter() {
  const {
    auth,
  } = useStore();

  return (
    <>
      <Switch>
        {
          !auth.data
            ? <>
                <Route path="/*">
                  <Redirect to="/login"/>
                </Route>

                <Route path="/login" exact component={ Login }/>
              </>
            : <Layout>
                {
                  routerConfig.routes.map( route => <Route { ...route } />)
                }
              </Layout>
        }
      </Switch>

      <Alert />
    </>
  );
};
