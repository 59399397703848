import { makeStyles } from '@material-ui/core/styles';

const appBarHeight = 85;
const appBarPaddingHorizontal = 15;

const drawerWidth = 305;


const useStyles = makeStyles( theme => ({
  container: {
    padding: 0,
    overflow: 'hidden',
  },

  appBar: {
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: appBarPaddingHorizontal,
    paddingRight: appBarPaddingHorizontal,
    height: appBarHeight,
  },

  logoBar: {
    height: 45,
  },

  drawerContent: {
    zIndex: 0,
    padding: 0,
    width: drawerWidth,
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },

  drawerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 85,
    backgroundColor: theme.palette.primary.main,
    color: '#FFF'
  },

  items: {
    padding: 0,
    paddingTop: 15,
  },

  item: {
    height: 55,

    '&.Mui-selected': {
      color: '#FFF',
    }
  },

  iconSelected: {
    color: '#FFF',
  },

  logo: {
    position: 'absolute',
    right: ( drawerWidth - 150 ) / 2,
    bottom: 15,
    left: ( drawerWidth - 150 ) / 2,
    height: 45,
  },

  content: {
    marginTop: appBarHeight,
    paddingTop: 25,
    width: '100%',
    height: `calc(100vh - ${ appBarHeight }px)`,
    overflow: 'auto',
  },
}));

export default useStyles;
