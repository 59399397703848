import api from '../config/api';

export async function getBenefits() {
  try {
    const { data } = await api.get('/benefit');

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};

export async function createBenefit( name, description, radio, fiber ) {
  try {
    const { data } = await api.post('/benefit', {
      name,
      description,
      radio,
      fiber,
    });

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};

export async function updateBenefit( id, name, description, radio, fiber ) {
  try {
    const { data } = await api.put(`/benefit/${ id }`, {
      name,
      description,
      radio,
      fiber,
    });

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};

export async function deleteBenefit( id ) {
  try {
    const { data } = await api.delete(`/benefit/${ id }`);

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};
