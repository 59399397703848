import {
  createTheme
} from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      light: '#24BAFF',
      main: '#2664C4',
      dark: '#0048BD',
    },

    secondary: {
      main: '#FB6219',
    },

    error: {
      main: '#FF3030',
    },

    background: {
      default: '#FFF',
    },

    action: {
      selected: '#2664C4',
      hover: '#2664C422',
    }
  },
});

export default theme;
