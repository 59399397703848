import React, {
  useEffect,
} from 'react';

import {
  useLocation,
} from 'react-router-dom';

import {
  useStore,
} from '../../store';

import {
  visitColumns,
} from '../../utils/columns';

import {
  DataTable,
} from '../../components';

export function Visit() {
  const location = useLocation();

  const {
    visit,
    config,
  } = useStore();

  useEffect(() => {
    if( location.pathname === '/visits')
      visit.loadVisits(config.data);

  }, [ location ]);

  return (
    <DataTable
      title="Visitas ao Site"
      columns={ visitColumns }
      store={ visit }
      showAddButton={false}
      showEditButton={false}
    />
  );
};
