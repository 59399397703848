import React from 'react';

import {
  LinearProgress,
} from '@material-ui/core';

import useStyles from './styles';

export function LoadingButton() {
  const Styles = useStyles();

  return (
    <LinearProgress className={ Styles.loading } color="primary" />
  );
};
