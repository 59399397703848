import React, {
  useEffect,
} from 'react';

import {
  useLocation,
} from 'react-router-dom';

import {
  useStore,
} from '../../store';

import {
  benefitColumns,
} from '../../utils/columns';

import {
  DataTable,
} from '../../components';

export function Benefit() {
  const location = useLocation();

  const {
    benefit,
  } = useStore();

  useEffect(() => {
    if( location.pathname === '/benefits');
      benefit.loadBenefits();

  }, [ location ]);

  return (
    <DataTable
      title="Benefícios"
      columns={ benefitColumns }
      store={ benefit }
    />
  );
};
