import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  Button,
  TextField,
  Modal,
  Paper,
  Toolbar,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import {
  useStore,
} from '../../store';

import {
  LoadingButton,
} from '../LoadingButton';

import useStyles from './styles';
import { Image } from '../Image';
import { DropzoneArea } from 'material-ui-dropzone';

export function ModalService() {
  const Styles = useStyles();

  const {
    alert,
    service,
  } = useStore();

  const [ name, setName ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ logo, setLogo ] = useState(null);

  const handleSave = useCallback( async () => {
    const formData = new FormData();

    formData.append('file', logo );
    formData.append('name', name );
    formData.append('description', description );

    const { success, message } = await service.handleSave( formData );

    alert.openAlert( success, message );

    if( success )
      service.handleCloseModal();
  }, [ logo, name, description ]);

  useEffect(() => {
    if( !service.isOpenModal ) {
      setLogo(null);
      setName('');
      setDescription('');
    } else {
      if( service.service?._id ) {
        setName( service.service.name );
        setDescription( service.service.description );
      }
    }
  }, [ service.isOpenModal, service.service ]);

  return (
    <Modal
      open={ service.isOpenModal }
      onClose={ service.handleCloseModal }
      className={ Styles.modal }
    >
      <Paper className={ Styles.modalView }>
        <Toolbar className={ Styles.modalToolbar }>
          <Typography
            noWrap
            className={ Styles.modalTitle }
            variant="h6"
          >
            {`${ service.service?.function || 'Adicionar'} Serviço`}
          </Typography>
        </Toolbar>

        <Container className={ Styles.bodyModal }>
          {
            service.service?.function === 'Excluir'
              ? <>
                  <Typography className={ Styles.question } component="h5">
                    Deseja realmente excluir o serviço
                  </Typography>

                  <Typography component="h5">
                    { service.service?.description }?
                  </Typography>
                </>
              : <>
                  <TextField
                    className={ Styles.input }
                    label="Nome"
                    placeholder="Nome"
                    variant="outlined"
                    value={ name }
                    onChange={ event => setName( event.target.value )}
                  />

                  <TextField
                    className={ Styles.input }
                    label="Descrição"
                    placeholder="Descrição"
                    variant="outlined"
                    value={ description }
                    onChange={ event => setDescription( event.target.value )}
                    multiline
                    maxRows={ 6 }
                  />

                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    dropzoneText={"Escolha uma logo, arraste ou clique 100x100"}
                    onChange={( files ) => setLogo( files[ 0 ])}
                    showPreviews={ true }
                    showPreviewsInDropzone={ false }
                    previewText="Imagem selecionada"
                    showAlerts={ false }
                    filesLimit={ 1 }
                    classes={{
                      root: Styles.drop
                    }}
                  />

                  {
                    service.service?.logo &&
                      <div className={ Styles.backView }>
                        <span>Logo Atual:</span>

                        <Image
                          image={ service.service?.logo }
                        />
                      </div>
                  }
                </>
          }

          <Container className={ Styles.modalActions }>
            <Button
              variant="outlined"
              color="inherit"
              onClick={ service.handleCloseModal }
            >
              { service.service?.function === 'Excluir' ? 'Não' : 'Cancelar'}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={ handleSave }
              disabled={
                service.saving ||
                !name
              }
            >
              { service.service?.function === 'Excluir' ? 'Sim' : 'Salvar'}

              { service.saving && <LoadingButton />}
            </Button>
          </Container>
        </Container>
      </Paper>
    </Modal>
  );
};
