import React, {
  createContext,
  useContext,
} from 'react';

import {
  useAuth,
  useAlert,
  usePost,
  useCity,
  usePlan,
  useInstallation,
  useUser,
  useConfig,
} from './hooks';
import { useBenefit } from './hooks/benefit';
import { useVisit } from './hooks/visit';
import { useService } from './hooks/service';

export const store = createContext({
  auth: {
    data: [],
    loading: false,
    logIn: async ( username, password ) => { return { success: true, message: 'Sucesso!'}},
    logOut: () => {},
  },

  alert: {
    open: false,
    success: '',
    message: null,
    fourSeconds: 0,
    openAlert: ( success, message ) => {},
    closeAlert: () => {},
  },

  city: {
    data: [],
    city: null,
    getting: false,
    saving: false,
    isOpenModal: false,
    loadCities: async () => { return { success: true, message: 'Sucesso!'}},
    handleOpenModal: ( data ) => {},
    handleCloseModal: () => {},
    handleSave: async ( formData ) => { return { success: true, message: 'Sucesso!'}},
  },

  plan: {
    data: [],
    plan: null,
    isDisabledBestSeller: false,
    getting: false,
    saving: false,
    isOpenModal: false,
    loadPlans: async () => { return { success: true, message: 'Sucesso!'}},
    handleOpenModal: ( data ) => {},
    handleCloseModal: () => {},
    handleSave: async ( name, description, type, value, city, benefit, servicesIncluded ) => { return { success: true, message: 'Sucesso!'}},
  },

  installation: {
    data: [],
    installation: null,
    getting: false,
    saving: false,
    isOpenModal: false,
    loadInstallations: async (config) => { return { success: true, message: 'Sucesso!'}},
    handleOpenModal: ( data ) => {},
    handleCloseModal: () => {},
    handleSave: async ( name, whatsapp, email, plan, config ) => { return { success: true, message: 'Sucesso!'}},
  },

  visit: {
    data: [],
    installation: null,
    getting: false,
    saving: false,
    isOpenModal: false,
    loadInstallations: async (config) => { return { success: true, message: 'Sucesso!'}},
    handleOpenModal: ( data ) => {},
    handleCloseModal: () => {},
    handleSave: async ( name, whatsapp, email, plan, config ) => { return { success: true, message: 'Sucesso!'}},
  },

  user: {
    data: [],
    user: null,
    getting: false,
    saving: false,
    changingAccess: false,
    isOpenModal: false,
    loadUsers: async () => { return { success: true, message: 'Sucesso!'}},
    handleOpenModal: ( data ) => {},
    handleCloseModal: () => {},
    handleSave: async ( name, username, password, type ) => { return { success: true, message: 'Sucesso!'}},
    handleChangeAccess: async ( id ) => { return { success: true, message: 'Sucesso!'}},
  },

  config: {
    data: [],
    getting: false,
    saving: false,
    isOpenModal: false,
    loadConfig: async () => { return { success: true, message: 'Sucesso!'}},
    handleSave: async ( formData ) => { return { success: true, message: 'Sucesso!'}},
  },

  post: {
    data: [],
    post: null,
    getting: false,
    saving: false,
    isOpenModal: false,
    loadPosts: async () => { return { success: true, message: 'Sucesso!'}},
    handleOpenModal: ( data ) => {},
    handleCloseModal: () => {},
    handleSave: async ( formData ) => { return { success: true, message: 'Sucesso!'}},
  },

  benefit: {
    data: [],
    benefit: null,
    getting: false,
    saving: false,
    isOpenModal: false,
    loadBenefits: async () => { return { success: true, message: 'Sucesso!'}},
    handleOpenModal: ( data ) => {},
    handleCloseModal: () => {},
    handleSave: async ( formData ) => { return { success: true, message: 'Sucesso!'}},
  },

  service: {
    data: [],
    benefit: null,
    getting: false,
    saving: false,
    isOpenModal: false,
    loadServices: async () => { return { success: true, message: 'Sucesso!'}},
    handleOpenModal: ( data ) => {},
    handleCloseModal: () => {},
    handleSave: async ( formData ) => { return { success: true, message: 'Sucesso!'}},
  },
});

const { Provider } = store;

export function StateProvider({ children }) {

  const alert = useAlert();
  const auth = useAuth();
  const city = useCity();
  const benefit = useBenefit();
  const service = useService();
  const plan = usePlan();
  const installation = useInstallation();
  const visit = useVisit();
  const user = useUser();
  const config = useConfig();
  const post = usePost();

  return (
    <Provider
      value={{
        alert,
        auth,
        post,
        city,
        benefit,
        service,
        plan,
        installation,
        visit,
        user,
        config,
      }}
    >
      { children }
    </Provider>
  );
};

export const useStore = () => useContext( store );
