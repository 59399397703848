import {
  AssessmentRounded,
  CameraAltRounded,
  InfoRounded,
  LocationCityRounded,
  CheckRounded,
  DoneAllRounded,
  WifiRounded,
  RecentActorsRounded,
  SettingsRounded,
  PublicRounded,
} from '@material-ui/icons';

import {
  Dash,
  Post,
  City,
  Plan,
  User,
  Config,
  Benefit,
  Installation,
  Service,
} from '../pages';
import { Visit } from '../pages/Visit';

export default {
  initial: '/dash',
  routes: [
    {
      key: 1,
      label: 'Painel',
      icon: AssessmentRounded,
      path: '/dash',
      exact: true,
      component: Dash,
    },

    {
      key: 2,
      label: 'Solicitações de Instalação',
      icon: InfoRounded,
      path: '/installations',
      exact: true,
      component: Installation,
    },

    {
      key: 3,
      label: 'Visitas ao Site',
      icon: PublicRounded,
      path: '/visits',
      exact: true,
      component: Visit,
    },

    {
      key: 4,
      label: 'Postagens',
      icon: CameraAltRounded,
      path: '/posts',
      exact: true,
      component: Post,
    },

    {
      key: 5,
      label: 'Cidades',
      icon: LocationCityRounded,
      path: '/cities',
      exact: true,
      component: City,
    },

    {
      key: 6,
      label: 'Benefícios',
      icon: CheckRounded,
      path: '/benefits',
      exact: true,
      component: Benefit,
    },

    {
      key: 7,
      label: 'Serviços Inclusos',
      icon: DoneAllRounded,
      path: '/services',
      exact: true,
      component: Service,
    },

    {
      key: 8,
      label: 'Planos',
      icon: WifiRounded,
      path: '/plans',
      exact: true,
      component: Plan,
    },

    {
      key: 9,
      label: 'Usuários',
      icon: RecentActorsRounded,
      path: '/users',
      exact: true,
      component: User,
    },

    {
      key: 10,
      label: 'Configurações',
      icon: SettingsRounded,
      path: '/config',
      exact: true,
      component: Config,
    },
  ]
};
