import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ status }) => ({
  statusView: {
    cursor: 'pointer',
    padding: 8,
    color: '#FFF',
    borderRadius: 8,
  },

  popover: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 15,
    paddingTop: 45,

    '& span': {
      textAlign: 'center',
      marginBottom: 8,
    },
  },

  close: {
    position: 'absolute',
    top: 5,
    left: 5,
  },
}));

export default useStyles;
