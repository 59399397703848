import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({
  button: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: 72,
    height: 88,
    padding: 8,
    border: 'none',
    borderRadius: 8,
    backgroundColor: '#DDD',
    color: '#000',
    transition: 'all .4s',
  },

  image: {
    width: '100%',
    '-webkit-filter': 'grayscale(100%)',
    filter: 'grayscale(100%)',
    transition: 'all .4s',
  },

  label: {
    fontSize: 12,
    fontWeight: 500,
  },

  imageSelected: {
    '-webkit-filter': 'grayscale(0%)',
    filter: 'grayscale(0%)',
  },

  buttonSelected: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF'
  },
}));

export default useStyles;
