import React, {
  useEffect,
} from 'react';

import {
  useLocation,
} from 'react-router-dom';

import {
  useStore,
} from '../../store';

import {
  postColumns,
} from '../../utils/columns';

import {
  DataTable,
} from '../../components';

export function Post() {
  const location = useLocation();

  const {
    post,
  } = useStore();

  useEffect(() => {
    if( location.pathname === '/posts');
      post.loadPosts();

  }, [ location ]);

  return (
    <DataTable
      title="Postagens"
      columns={ postColumns }
      store={ post }
    />
  );
};
