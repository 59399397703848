import api from '../config/api';

export async function getInstallations() {
  try {
    const { data } = await api.get('/installation');

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};

export async function createInstallation( name, whatsapp, email, plan_id ) {
  try {
    const { data } = await api.post('/installation', {
      name,
      whatsapp,
      email,
      plan_id,
    });

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};

export async function updateInstallation( id, name, whatsapp, email, plan_id ) {
  try {
    const { data } = await api.put(`/installation/${ id }`, {
      name,
      whatsapp,
      email,
      plan_id,
    });

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};

export async function updateStatusInstallation( id, status ) {
  try {
    const { data } = await api.patch(`/installation/${ id }`, {
      status,
    });

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};

export async function deleteInstallation( id ) {
  try {
    const { data } = await api.delete(`/installation/${ id }`);

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};
