import React, {
  useEffect,
  useState,
} from 'react';

import {
  Paper,
  Container,
  Typography,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  CircularProgress,
  IconButton,
  Collapse,
  Box,
} from '@material-ui/core';

import {
  CreateRounded,
  DeleteRounded,
  KeyboardArrowDown,
} from '@material-ui/icons';

import {
  Pagination,
} from '@material-ui/lab';

import useStyles from './styles';

export function DataTable({ title, columns, store, showAddButton = true, showEditButton = true, showDeleteButton = true }) {
  const Styles = useStyles();

  const isCollapse = columns.some( column => column.collapse )

  const rowsPerPage = 5;
  const [ page, setPage ] = useState( 1 );
  const [ count, setCount ] = useState( 1 );

  useEffect(() => {
    let count = 0;

    columns.forEach( column => count = count + column.span );

    if( isCollapse ) count ++;

    setCount( count + 1 );
  }, []);

  return (
    <Paper className={ Styles.tableView }>
      <Container className={ Styles.head }>
        <Typography
          variant="subtitle1"
          className={ Styles.title }
        >
          { title }
        </Typography>

        {
          showAddButton && (
            <Button
              variant="contained"
              color="primary"
              onClick={ store.handleOpenModal }
            >
              Adicionar
            </Button>
          )
        }
      </Container>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {
                isCollapse && <TableCell style={{ width: '5%'}} align="center" />
              }

              {
                columns
                  .filter( row => !row.collapse )
                  .map(( column, index ) =>
                    <TableCell
                      key={ index }
                      style={{ width: column.width }}
                      align={ column.align }
                      colSpan={ column.span }
                    >
                      { column.label }
                    </TableCell>
                  )
              }

              <TableCell style={{ width: '5%'}} align="center" colSpan={ 2 }>Ações</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
              store.getting
                ? <TableRow>
                    <TableCell colSpan={ count }  align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                : store.data.length > 0
                    ? store.data
                        .slice(( page - 1 ) * rowsPerPage, ( page - 1 ) * rowsPerPage + rowsPerPage )
                        .map( row =>
                          <TableRowCollapse
                            key={ row._id }
                            row={ row }
                            columns={ columns }
                            store={ store }
                            isCollapse={ isCollapse }
                            showEditButton={showEditButton}
                            showDeleteButton={showDeleteButton}
                          />
                        )
                    : <TableRow>
                        <TableCell colSpan={ count } align="center">
                          <Typography variant="h6">Nenhuma dado cadastrado...</Typography>
                        </TableCell>
                      </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>

      <Container className={ Styles.footer }>
        <Pagination
          page={ page }
          count={ Math.ceil( store.data.length / rowsPerPage )}
          color="primary"
          shape="rounded"
          size="small"
          onChange={( event, page ) => setPage( page )}
        />
      </Container>
    </Paper>
  );
};

function TableRowCollapse({ row, columns, store, isCollapse, showEditButton, showDeleteButton }) {
  const Styles = useStyles();

  const [ open, setOpen ] = useState( false );

  return (
    <>
      <TableRow>
        {
          isCollapse &&
            <TableCell>
              <IconButton
                onClick={() => setOpen( !open )}
                color="primary"
              >
                <KeyboardArrowDown
                  className={`${ Styles.icon } ${ open && Styles.open }`}
                />
              </IconButton>
            </TableCell>
        }

        {
          columns
            .filter( column => !column.collapse )
            .map( column =>
              <TableCell key={ column.key } align={ column.align }>{ row[ column.key ]}</TableCell>
            )
        }

        {
          showEditButton && (
            <TableCell>
              <IconButton
                color="primary"
                onClick={() => store.handleOpenModal({ function: 'Alterar', ...row })}
              >
                <CreateRounded />
              </IconButton>
            </TableCell>
          )
        }

        {
          showDeleteButton && (
            <TableCell>
              <IconButton
                className={ Styles.delete }
                onClick={() => store.handleOpenModal({ function: 'Excluir', ...row })}
              >
                <DeleteRounded />
              </IconButton>
            </TableCell>
          )
        }
      </TableRow>

      {
        isCollapse &&
          <TableRow>
            <TableCell
              colSpan={ columns.length + 2 }
              style={{ paddingBottom: 0, paddingTop: 0 }}
            >
              <Collapse in={ open } timeout="auto" >
                <Box margin={ 2 }>
                  <div className={ Styles.collapse }>
                    {
                      columns
                        .filter( column => column.collapse )
                        .map(( column, index, array )=>
                          <div
                            style={{ width: `${ 100 / array.length }%`}}
                          >
                            <Typography variant="h6" gutterBottom component="div">
                              { column?.label }
                            </Typography>

                            <div className={ Styles.description }>
                              <Typography variant="body1" component="span">
                                { row[ column.key ]}
                              </Typography>
                            </div>
                          </div>
                        )
                    }
                  </div>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
      }
    </>
  );
};
