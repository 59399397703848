import React, {
  useEffect,
} from 'react';

import {
  useLocation,
} from 'react-router-dom';

import {
  useStore,
} from '../../store';

import {
  serviceColumns,
} from '../../utils/columns';

import {
  DataTable,
} from '../../components';

export function Service() {
  const location = useLocation();

  const {
    service,
  } = useStore();

  useEffect(() => {
    if( location.pathname === '/services');
      service.loadServices();

  }, [ location ]);

  return (
    <DataTable
      title="Serviços Inclusos"
      columns={ serviceColumns }
      store={ service }
    />
  );
};
