import {
  useState,
  useCallback,
  useMemo,
} from 'react';

import {
  getConfig,
  createConfig,
} from '../../services';

export function useConfig() {
  const [ getting, setGetting ] = useState( false );
  const [ saving, setSaving ] = useState( false );

  const [ data, setData ] = useState({});

  const loadConfig = useCallback( async () => {
    setGetting( true );

    const { success, message, result } = await getConfig();

    if( success ) {
      setData( result );
    }

    setGetting( false );

    return { success, message };
  }, []);

  const handleSave = useCallback( async ( formData ) => {
    setSaving( true );

    const { success, message } = await createConfig( formData );

    setSaving( false );

    if( success ) loadConfig();

    return { success, message };
  }, []);

  return useMemo(() => ({
    data,
    getting,
    saving,
    loadConfig,
    handleSave,
  }), [
    data,
    getting,
    saving,
    loadConfig,
    handleSave,
  ]);
};
