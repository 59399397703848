import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  Button,
  TextField,
  Modal,
  Paper,
  Toolbar,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';

import IntlCurrencyInput from 'react-intl-currency-input';

import {
  useStore,
} from '../../store';

import {
  LoadingButton,
} from '../LoadingButton';

import useStyles from './styles';
import { Switch } from '../Switch';
import { ServiceButton } from '../ServiceButton';

export function ModalPlan() {
  const Styles = useStyles();

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const {
    alert,
    plan,
    city,
    benefit,
    service,
  } = useStore();

  const [ name, setName ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ type, setType ] = useState('');
  const [ value, setValue ] = useState('');
  const [ bestSeller, setBestSeller ] = useState(false);
  const [ cities, setCities ] = useState([]);
  const [ benefits, setBenefits ] = useState([]);
  const [ servicesIncluded, setServices ] = useState([]);

  function handleSelectCity( checked, id ) {
    setCities( cities.map( city => {
      if( city._id === id ) {
        city.selected = checked;

        return city;
      } else {
        return city;
      }
    }));
  };

  function handleSelectBenefit( checked, id ) {
    setBenefits( benefits.map( benefit => {
      if( benefit._id === id ) {
        benefit.selected = checked;

        return benefit;
      } else {
        return benefit;
      }
    }));
  };

  function handleSelectService( id ) {
    setServices( servicesIncluded.map( service => {
      if( service._id === id ) {
        service.selected = !service.selected;

        return service;
      } else {
        return service;
      }
    }));
  };

  const handleSave = useCallback( async () => {
    const { success, message } = await plan.handleSave( name, description, type, value, bestSeller, cities.filter( city => city.selected ).map( city => city._id ), benefits.filter( benefit => benefit.selected ).map( benefit => benefit._id ), servicesIncluded.filter( serviceIncluded => serviceIncluded.selected ).map( serviceIncluded => serviceIncluded._id ));

    alert.openAlert( success, message );

    if( success )
      plan.handleCloseModal();
  }, [ name, description, type, value, bestSeller, cities ]);

  async function loadCities() {
    await city.loadCities();
  };

  async function loadBenefits() {
    await benefit.loadBenefits();
  };

  async function loadServices() {
    await service.loadServices();
  };

  useEffect(() => {
    if( !plan.isOpenModal ) {
      setName('');
      setDescription('');
      setType('');
      setValue('');
      setBestSeller(false);

      setCities( city.data.map( city => {
        return {
          ...city,
          selected: false,
        };
      }));

      setBenefits( benefit.data.map( benefit => {
        return {
          ...benefit,
          selected: false,
        };
      }));

      setServices( service.data.map( service => {
        return {
          ...service,
          selected: false,
        };
      }));
    } else {
      if( city.data.length === 0 ) {
        loadCities();
      }

      if( benefit.data.length === 0 ) {
        loadBenefits();
      }

      if( service.data.length === 0 ) {
        loadServices();
      }

      if( plan.plan?._id ) {
        setName( plan.plan.name );
        setDescription( plan.plan.description );
        setType( plan.plan.type );
        setValue( plan.plan.value );
        setBestSeller( !!plan.plan.bestSeller );

        setCities( city.data.map( city => {
          return {
            ...city,
            selected: plan.plan.cities.some( someCity => someCity._id === city._id ),
          };
        }));

        setBenefits( benefit.data.map( benefit => {
          return {
            ...benefit,
            selected: plan.plan.benefits.some( someBenefit => someBenefit._id === benefit._id ),
          };
        }));

        setServices( service.data.map( service => {
          return {
            ...service,
            selected: plan.plan.servicesIncluded.some( someService => someService._id === service._id ),
          };
        }));
      }
    }
  }, [ plan.isOpenModal, plan.plan, city.data, benefit.data, service.data ]);

  return (
    <Modal
      open={ plan.isOpenModal }
      onClose={ plan.handleCloseModal }
      className={ Styles.modal }
    >
      <Paper className={ Styles.modalView }>
        <Toolbar className={ Styles.modalToolbar }>
          <Typography
            noWrap
            className={ Styles.modalTitle }
            variant="h6"
          >
            {`${ plan.plan?.function || 'Adicionar'} Plano`}
          </Typography>
        </Toolbar>

        <Container className={ Styles.bodyModal }>
          {
            plan.plan?.function === 'Excluir'
              ? <>
                  <Typography className={ Styles.question } component="h5">
                    Deseja realmente excluir o plano
                  </Typography>

                  <Typography component="h5">
                    { plan.plan?.name }?
                  </Typography>
                </>
              : <>
                  <TextField
                    className={ Styles.input }
                    label="Nome"
                    placeholder="Nome"
                    variant="outlined"
                    value={ name }
                    onChange={ event => setName( event.target.value )}
                  />

                  <FormControl
                    component="fieldset"
                    className={ Styles.input }
                  >
                    <FormLabel component="legend">Tecnologia</FormLabel>

                    <RadioGroup
                      value={ type }
                      onChange={( event ) => setType( event.target.value )}
                    >
                      <FormControlLabel
                        value="Fibra Óptica"
                        control={<Radio color="primary"/>}
                        label="Fibra Óptica"
                      />

                      <FormControlLabel
                        value="Via Rádio"
                        control={<Radio color="primary"/>}
                        label="Via Rádio"
                      />
                    </RadioGroup>
                  </FormControl>

                  <TextField
                    className={ Styles.input }
                    label="Valor"
                    placeholder="Valor"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: IntlCurrencyInput,
                    }}
                    inputProps={{
                      currency: 'BRL',
                      config: currencyConfig,
                      value: value,
                      onChange: ( event, value, maskedValue ) => setValue( maskedValue ),
                    }}
                  />

                  <TextField
                    className={ Styles.input }
                    label="Observação"
                    placeholder="Descrição"
                    variant="outlined"
                    value={ description }
                    onChange={ event => setDescription( event.target.value )}
                    multiline
                    maxRows={ 6 }
                  />

                  <Switch
                    label="Mais Vendido"
                    value={bestSeller}
                    onChange={(value) => setBestSeller(value)}
                    disabled={plan.plan?._id ? (plan.isDisabledBestSeller && !bestSeller) : plan.isDisabledBestSeller}
                  />

                  <span className={ Styles.checkboxLabel}>Cidades</span>

                  <div className={ Styles.checkboxView }>
                    {
                      cities.map( city =>
                        <FormControlLabel
                          key={city?.name}
                          control={
                            <Checkbox
                              checked={ city.selected }
                              onChange={ event => handleSelectCity( event.target.checked, city._id )}
                              color="primary"
                            />}
                          label={ city?.name }
                        />
                      )
                    }
                  </div>

                  <span className={ Styles.checkboxLabel}>Benefícios</span>

                  <div className={ Styles.checkboxView }>
                    {
                      benefits.filter( benefit => {
                        if( type === 'Fibra Óptica')
                          return benefit.fiber.props.status;
                        else
                          return benefit.radio.props.status;
                      }).map( benefit =>
                        <FormControlLabel
                          key={benefit._id}
                          control={
                            <Checkbox
                              checked={ benefit.selected }
                              onChange={ event => handleSelectBenefit( event.target.checked, benefit._id )}
                              color="primary"
                            />}
                          label={ benefit?.name }
                        />
                      )
                    }
                  </div>

                  <span className={ Styles.checkboxLabel}>Serviços Inclusos</span>

                  <div className={ Styles.servicesView }>
                    {
                      servicesIncluded.map( service =>
                        <ServiceButton
                          key={service._id}
                          label={service.name}
                          logo={service.logo}
                          selected={servicesIncluded.some((s) => s._id === service._id && s.selected)}
                          onClick={() => handleSelectService(service._id)}
                        />
                      )
                    }
                  </div>
                </>
          }

          <Container className={ Styles.modalActions }>
            <Button
              variant="outlined"
              color="inherit"
              onClick={ plan.handleCloseModal }
            >
              { plan.plan?.function === 'Excluir' ? 'Não' : 'Cancelar'}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={ handleSave }
              disabled={
                plan.saving ||
                !name ||
                !type ||
                !value ||
                !city ||
                city === 'Selecione'
              }
            >
              { plan.plan?.function === 'Excluir' ? 'Sim' : 'Salvar'}

              { plan.saving && <LoadingButton />}
            </Button>
          </Container>
        </Container>
      </Paper>
    </Modal>
  );
};
