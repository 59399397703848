import {
  useState,
  useCallback,
  useMemo,
} from 'react';

import {
  getVisits,
  deleteVisit,
} from '../../services';

import {
  visitTransform,
} from '../../utils/helpers/transformData';

export function useVisit() {
  const [ getting, setGetting ] = useState( false );
  const [ saving, setSaving ] = useState( false );
  const [ isOpenModal, setIsOpenModal ] = useState( false );

  const [ data, setData ] = useState([]);
  const [ visit, setVisit ] = useState( null );

  const loadVisits = useCallback( async (config) => {
    setGetting( true );

    const { success, message, result } = await getVisits();

    if( success ) {
      setData( visitTransform( result, config ));
    }

    setGetting( false );

    return { success, message };
  }, []);

  const handleOpenModal = useCallback(( data ) => {
    setVisit( data );

    setIsOpenModal( true );
  }, []);

  const handleCloseModal = useCallback(() => {
    setVisit( null );

    setIsOpenModal( false );
  }, []);

  const handleSave = useCallback( async () => {
    setSaving( true );

    const { success, message } = await deleteVisit( visit._id );

    setSaving( false );

    if( success ) loadVisits();

    return { success, message };
  }, [ visit ]);

  return useMemo(() => ({
    data,
    visit,
    getting,
    saving,
    isOpenModal,
    loadVisits,
    handleOpenModal,
    handleCloseModal,
    handleSave,
  }), [
    data,
    visit,
    getting,
    saving,
    isOpenModal,
    loadVisits,
    handleOpenModal,
    handleCloseModal,
    handleSave,
  ]);
};
