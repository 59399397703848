import useStyles from "./styles";

export const ServiceButton = ({
  logo,
  label,
  selected,
  onClick = () => {},
}) => {
  const Styles = useStyles();

  return (
    <button
      className={`${Styles.button}${ selected ? ` ${Styles.buttonSelected}` : ''}`}
      onClick={onClick}
    >
      <img
        className={`${Styles.image}${ selected ? ` ${Styles.imageSelected}` : ''}`}
        src={`${ process.env.REACT_APP_API_URL }/image/${ logo }`}
      />

      <span className={Styles.label}>{label}</span>
    </button>
  );
};
