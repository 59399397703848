import {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import {
  login,
} from '../../services';

export function useAuth() {
  const [ data, setData ] = useState( null );

  const [ loading, setLoading ] = useState( false );

  const logIn = useCallback( async ( username, password ) => {
    setLoading( true );

    const { success, message, result } = await login( username, password );

    if( success ) {
      setData( result );

      localStorage.setItem('InfoTurboTelecom@AuthenticatedUser', JSON.stringify( result ));
    }

    setLoading( false );

    return { success, message };
  }, []);

  const logOut = useCallback(() => {
    setData( null );
  }, []);

  return useMemo(() => ({
    data,
    loading,
    logIn,
    logOut,
  }), [
    data,
    loading,
    logIn,
    logOut,
  ]);
};
