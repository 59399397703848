import {
  Status,
  Image,
} from '../../components';
import { InstallationStatus } from '../../components/InstallationStatus';
import { format } from 'date-fns';

export function cityTransform( data ) {
  return data.map( row => {
    return {
      ...row,
      img:
        <Image
          image={ row.background }
        />
    };
  });
};

export function planTransform( data ) {
  return data.map( row => {
    return {
      ...row,
      cities_names: row.cities
        .map( city => city.name ).join('\n'),
      benefits_names: row.benefits
        .map( benefit => benefit.name ).join('\n'),
      servicesIncluded_names: row.servicesIncluded
        .map( serviceIncluded => serviceIncluded.name ).join('\n'),
    };
  });
};

export function installationTransform( data, config ) {
  return data.map( row => {
    return {
      ...row,
      _email: row.email ? row.email : 'Não informado',
      _whatsapp: config.contactMessage
        ? <a
            target="_blank"
            rel="noreferrer"
            href={`https://wa.me/55${configPhoneNumber(row.whatsapp)}/?text=${configMessageContact({
              name: row.name,
              plan: row.plan.name,
              price: row.plan.value,
            }, config.contactMessage)}`}
          >
            { row.whatsapp }
          </a>
        : row.whatsapp,
      _status:
        <InstallationStatus
          id={ row._id }
          status={row.status}
        />,
      plaName: row.plan.name,
    };
  });
};

export function visitTransform( data ) {
  return data.map( row => {
    return {
      ...row,
      _firstVisit: format(row.firstVisit, 'dd/MM/yyyy HH:mm'),
      _lastVisit: format(row.lastVisit, 'dd/MM/yyyy HH:mm'),
    };
  });
};

export function userTransform( data ) {
  return data.map( row => {
    const {
      type,
      active,
      ...rest
    } = row;

    return {
      ...rest,
      activeButtonIcon:
        <Status
          status={ active }
          id={ rest._id }
        />,
      type,
      typeName: ( type === 0 && 'Usuário') || ( type === 1 && 'Administrador'),
    };
  });
};

export function postTransform( data ) {
  return data.map( row => {
    return {
      ...row,
      img:
        <Image
          image={ row.file }
        />
    };
  });
};

export function benefitTransform( data ) {
  return data.map( row => {
    return {
      ...row,
      radio:
        <Status
          status={ row.radio }
        />,
      fiber:
        <Status
          status={ row.fiber }
        />,
    };
  });
};

export function serviceTransform( data ) {
  return data.map( row => {
    return {
      ...row,
      _logo:
        <Image
          image={ row.logo }
        />
    };
  });
};

const configPhoneNumber = (phone) => {
  phone = phone.replaceAll('(', '');
  phone = phone.replaceAll(')', '');
  phone = phone.replaceAll('-', '');
  phone = phone.replaceAll(' ', '');

  return phone;
};

const configMessageContact = (data, message) => {
  message = message.replace('$_nome', data.name)
  message = message.replace('$_plano', data.plan)
  message = message.replace('$_preco', data.price)

  message = message.replaceAll(' ', '%20');

  return message;
};
