import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  Button,
  TextField,
  Modal,
  Paper,
  Toolbar,
  Typography,
  MenuItem,
} from '@material-ui/core';

import {
  Autocomplete,
} from '@material-ui/lab';

import states from '../../utils/estados.json';

import {
  useStore,
} from '../../store';

import {
  LoadingButton,
} from '../LoadingButton';

import useStyles from './styles';

export function ModalUser() {
  const Styles = useStyles();

  const {
    alert,
    user,
  } = useStore();

  const [ name, setName ] = useState('');
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ type, setType ] = useState( 0 );

  const handleSave = useCallback( async () => {
    const { success, message } = await user.handleSave( name, username, password, type );

    alert.openAlert( success, message );

    if( success)
      user.handleCloseModal();
  }, [ name, username, password, type ]);

  useEffect(() => {
    if( !user.isOpenModal ) {
        setName('');
        setUsername('');
        setPassword('');
        setType( 0 );
    } else {
      if( user.user?._id ) {
        setName( user.user.name );
        setUsername( user.user.username );
        setType( user.user.type );
      }
    }
  }, [ user.isOpenModal, user.user ]);

  return (
    <Modal
      open={ user.isOpenModal }
      onClose={ user.handleCloseModal }
      className={ Styles.modal }
    >
      <Paper className={ Styles.modalView }>
        <Toolbar className={ Styles.modalToolbar }>
          <Typography
            noWrap
            className={ Styles.modalTitle }
            variant="h6"
          >
            {`${ user.user?.function || 'Adicionar'} Usuário`}
          </Typography>
        </Toolbar>

        <Container className={ Styles.bodyModal }>
          {
            user.user?.function === 'Excluir'
              ? <>
                  <Typography className={ Styles.question } component="h5">
                    Deseja realmente excluir o usuário
                  </Typography>

                  <Typography component="h5">
                    { user.user?.name }?
                  </Typography>
                </>
              : <>
                  <TextField
                    className={ Styles.input }
                    label="Nome"
                    placeholder="Nome"
                    variant="outlined"
                    value={ name }
                    onChange={ event => setName( event.target.value )}
                  />

                  <TextField
                    className={ Styles.input }
                    label="Usuário"
                    placeholder="Usuário"
                    variant="outlined"
                    value={ username }
                    onChange={ event => setUsername( event.target.value )}
                  />

                  <TextField
                    className={ Styles.input }
                    label="Senha"
                    placeholder="Senha"
                    variant="outlined"
                    value={ password }
                    type="password"
                    onChange={ event => setPassword( event.target.value )}
                  />

                  <TextField
                    className={ Styles.input }
                    label="Tipo"
                    placeholder="Tipo"
                    variant="outlined"
                    select
                    value={ type }
                    onChange={ event => setType( event.target.value )}
                  >
                    <MenuItem value={ 0 }>Usuário Comum</MenuItem>
                    <MenuItem value={ 1 }>Administrador</MenuItem>
                  </TextField>
                </>
          }

          <Container className={ Styles.modalActions }>
            <Button
              variant="outlined"
              color="inherit"
              onClick={ user.handleCloseModal }
            >
              { user.user?.function === 'Excluir' ? 'Não' : 'Cancelar'}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={ handleSave }
              disabled={
                user.saving ||
                !name ||
                !username ||
                ( !password && !user?.user?._id )
              }
            >
              { user.user?.function === 'Excluir' ? 'Sim' : 'Salvar'}

              { user.saving && <LoadingButton />}
            </Button>
          </Container>
        </Container>
      </Paper>
    </Modal>
  );
};
