import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({
  loading: {
    position: 'absolute',
    color: theme.palette.primary.contrastText,
    left: 0,
    right: 0,
    bottom: 0,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
}));

export default useStyles;
