import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';

import {
  useLocation,
} from 'react-router-dom';

import {
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';

import InputMask from "react-input-mask";

import {
  DropzoneArea,
} from 'material-ui-dropzone';

import {
  useStore,
} from '../../store';

import {
  LoadingButton,
  Image,
} from '../../components';

import useStyles from './styles';

export function Config() {
  const Styles = useStyles();
  const location = useLocation();

  const {
    alert,
    config,
  } = useStore();

  const [ message, setMessage ] = useState('$_nome $_plano $_preco');
  const [ background, setBackground ] = useState( null );

  function handleReset() {
    if( config.data?._id ) {
      setBackground( config.data.background );
      setMessage( config.data.message ?? '$_nome $_plano $_preco');
    } else {
      setBackground( null );
      setMessage('$_nome $_plano $_preco');
    }
  };

  const handleSave = useCallback( async () => {
    const formData = new FormData();

    formData.append('file', background );
    formData.append('message', message );

    const { success, message: _message } = await config.handleSave( formData, config );

    alert.openAlert( success, _message );

    if( success )
      setBackground( null );
  }, [ background, message ]);

  useEffect(() => {
    if( location.pathname === '/config');
      config.loadConfig();

  }, [ location ]);

  useEffect(() => {
    if( config.data?._id )
      handleReset();
  }, [ config.data ]);

  return (
    <Container className={ Styles.container }>
      <Paper className={ Styles.paper }>
        <Typography
          variant="subtitle1"
          className={ Styles.title }
        >
          Configurações
        </Typography>

        <TextField
          className={ Styles.input }
          label="Mensagem de contato"
          placeholder="Mensagem de contato"
          variant="outlined"
          multiline
          maxRows={4}
          minRows={4}
          value={ message }
          onChange={ event => setMessage( event.target.value )}
        />

        <div className={Styles.instructions}>
          <div className={Styles.instructionsRow}>
            <span>$_nome</span>
            <span>Nome do cliente</span>
          </div>

          <div className={Styles.instructionsRow}>
            <span>$_plano</span>
            <span>Nome do plano</span>
          </div>

          <div className={Styles.instructionsRow}>
            <span>$_preco</span>
            <span>Preço do plano</span>
          </div>
        </div>

        <DropzoneArea
          acceptedFiles={['image/*']}
          dropzoneText={"Escolha um background, arraste ou clique"}
          onChange={( files ) => setBackground( files[ 0 ])}
          showPreviews={ true }
          showPreviewsInDropzone={ false }
          previewText="Imagem selecionada"
          showAlerts={ false }
          filesLimit={ 1 }
        />

        {
          config.data?.background &&
            <div className={ Styles.backView }>
              <span>Background Atual:</span>

              <Image
                image={ config.data?.background }
              />
            </div>
        }

        <Container className={ Styles.actions }>
          <Button
            color="primary"
            variant="outlined"
            size="large"
            onClick={ handleReset }
            disabled={
              config.getting ||
              (
                !background ||
                !message
              )
            }
          >
            Resetar
          </Button>

          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={ handleSave }
            disabled={
              config.getting ||
              config.saving ||
              (
                !background ||
                !message
              )
            }
          >
            Salvar

            { config.saving && <LoadingButton />}
          </Button>
        </Container>

        { config.getting && <LoadingButton />}
      </Paper>
    </Container>
  );
};
