import api from '../config/api';

export async function login( username, password ) {
  try {
    const { data } = await api.post('/auth', {
      username,
      password,
    });

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};
