import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  Button,
  TextField,
  Modal,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';

import InputMask from "react-input-mask";

import {
  Autocomplete,
} from '@material-ui/lab';

import {
  useStore,
} from '../../store';

import {
  LoadingButton,
} from '../LoadingButton';

import useStyles from './styles';

export function ModalVisit() {
  const Styles = useStyles();

  const {
    alert,
    visit,
  } = useStore();

  const handleSave = useCallback( async () => {
    const { success, message } = await visit.handleSave();

    alert.openAlert( success, message );

    if( success )
      visit.handleCloseModal();
  }, []);

  return (
    <Modal
      open={ visit.isOpenModal }
      onClose={ visit.handleCloseModal }
      className={ Styles.modal }
    >
      <Paper className={ Styles.modalView }>
        <Toolbar className={ Styles.modalToolbar }>
          <Typography
            noWrap
            className={ Styles.modalTitle }
            variant="h6"
          >
            {`${ visit.visit?.function || 'Adicionar'} Solicitação`}
          </Typography>
        </Toolbar>

        <Container className={ Styles.bodyModal }>
          <Typography className={ Styles.question } component="h5">
            Deseja realmente excluir a(s) visita(s) de
          </Typography>

          <Typography component="h5">
            { visit.visit?.ipAddress }?
          </Typography>

          <Container className={ Styles.modalActions }>
            <Button
              variant="outlined"
              color="inherit"
              onClick={ visit.handleCloseModal }
            >
              Não
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={ handleSave }
            >
              Sim

              { visit.saving && <LoadingButton />}
            </Button>
          </Container>
        </Container>
      </Paper>
    </Modal>
  );
};
