import { makeStyles } from '@material-ui/core/styles';

import bg from '../../assets/bg.jpg';

const useStyles = makeStyles( theme => ({
  container: {
    width: '100%',
    height: '100vh',
    padding: 0,
  },

  blur: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba( 0, 0, 0, .4)'
  },

  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
    maxWidth: 545,
    height: '90%',
    maxHeight: 495,
    paddingTop: 45,
    paddingRight: 25,
    paddingBottom: 45,
    paddingLeft: 25,
  },

  logo: {
    height: 55,
  },

  input: {
    width: '75%',
  },

  button: {
    width: '65%',
    height: 55,
  },
}));

export default useStyles;
