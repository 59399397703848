import {
  useState,
  useCallback,
  useMemo,
} from 'react';

import {
  getServices,
  createService,
  updateService,
  deleteService,
} from '../../services';

import {
  serviceTransform,
} from '../../utils/helpers/transformData';

export function useService() {
  const [ getting, setGetting ] = useState( false );
  const [ saving, setSaving ] = useState( false );
  const [ isOpenModal, setIsOpenModal ] = useState( false );

  const [ data, setData ] = useState([]);
  const [ service, setService ] = useState( null );

  const loadServices = useCallback( async () => {
    setGetting( true );

    const { success, message, result } = await getServices();

    if( success ) {
      setData( serviceTransform( result ));
    }

    setGetting( false );

    return { success, message };
  }, []);

  const handleOpenModal = useCallback(( data ) => {
    setService( data );

    setIsOpenModal( true );
  }, []);

  const handleCloseModal = useCallback(() => {
    setService( null );

    setIsOpenModal( false );
  }, []);

  const handleSave = useCallback( async ( formData ) => {
    setSaving( true );

    const { success, message } = service?._id
      ? service?.function === 'Excluir'
        ? await deleteService( service._id )
        : await updateService( service._id, formData )
      : await createService( formData );

    setSaving( false );

    if( success ) loadServices();

    return { success, message };
  }, [ service ]);

  return useMemo(() => ({
    data,
    service,
    getting,
    saving,
    isOpenModal,
    loadServices,
    handleOpenModal,
    handleCloseModal,
    handleSave,
  }), [
    data,
    service,
    getting,
    saving,
    isOpenModal,
    loadServices,
    handleOpenModal,
    handleCloseModal,
    handleSave,
  ]);
};
