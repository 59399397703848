import React, {
  useEffect,
  useState,
} from 'react';

import {
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import {
  Container,
  AppBar,
  IconButton,
  SwipeableDrawer,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import {
  MenuRounded,
  CloseRounded,
  ExitToAppRounded,
} from '@material-ui/icons';

import {
  useStore,
} from '../../store';

import {
  ModalCity,
  ModalPlan,
  ModalUser,
  ModalPost,
  ModalBenefit,
  ModalInstallation,
} from '../index';

import routerConfig from '../../config/routes';

import logo from '../../assets/logo.png';
import logo_branca from '../../assets/logo_branca.png';

import useStyles from './styles';
import { ModalVisit } from '../ModalVisit';
import { ModalService } from '../ModalService';

export function Layout({ children }) {
  const iOS = /iPad|iPhone|iPod/.test( navigator.userAgent );

  const Styles = useStyles();
  const location = useLocation();

  const {
    auth,
  } = useStore();

  const [ openDrawer, setOpenDrawer ] = useState( true );

  useEffect(() => {
    setOpenDrawer( false );
  }, [ location ]);

  return (
    <Container
      maxWidth={ false }
      className={ Styles.container }
    >
      <AppBar
        position="absolute"
        className={ Styles.appBar }
      >
        <IconButton
          color="inherit"
          onClick={() => setOpenDrawer( true )}
        >
          <MenuRounded />
        </IconButton>

        <img
          src={ logo_branca }
          alt="InfoTurbo Telecom"
          className={ Styles.logoBar }
        />

        <IconButton
          color="inherit"
          onClick={ auth.logOut }
        >
          <ExitToAppRounded />
        </IconButton>
      </AppBar>

      <SwipeableDrawer
        anchor="left"
        open={ openDrawer }
        onOpen={() => setOpenDrawer( true )}
        onClose={() => setOpenDrawer( false )}
        disableBackdropTransition={ !iOS }
        disableDiscovery={ iOS }
      >
        <Container className={ Styles.drawerContent }>
          <Container className={ Styles.drawerHeader }>
            <IconButton
              color="inherit"
              onClick={() => setOpenDrawer( false )}
            >
              <CloseRounded />
            </IconButton>

            <Typography>{ auth.data.name }</Typography>
          </Container>

          {
            routerConfig.routes.map( route => <ListItemIsSelected key={ route.key } item={ route } />)
          }

          <img
            src={ logo }
            alt="InfoTurbo Telecom"
            className={ Styles.logo }
          />
        </Container>
      </SwipeableDrawer>

      <Container
        maxWidth={ false }
        className={ Styles.content }
      >
        { children }
      </Container>

      <ModalCity />
      <ModalBenefit />
      <ModalService />
      <ModalPlan />
      <ModalInstallation />
      <ModalUser />
      <ModalPost />
      <ModalVisit />
    </Container>
  );
};

function ListItemIsSelected({ item }) {
  const Styles = useStyles();

  const {
    icon: Icon,
    label,
    path,
  } = item;

  const history = useHistory();
  const match = useRouteMatch( path );

  return (
    <ListItem
      button
      onClick={() => history.push( path )}
      className={ Styles.item }
      selected={ !!match }
      divider
    >
      <ListItemIcon className={`${ !!match && Styles.iconSelected }`}>
        <Icon />
      </ListItemIcon>

      <ListItemText>
        { label }
      </ListItemText>
    </ListItem>
  );
};
