import {
  useState,
  useCallback,
  useMemo,
} from 'react';

import {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  changeUserAccess,
} from '../../services';

import {
  userTransform,
} from '../../utils/helpers/transformData';

export function useUser() {
  const [ getting, setGetting ] = useState( false );
  const [ saving, setSaving ] = useState( false );
  const [ isOpenModal, setIsOpenModal ] = useState( false );

  const [ data, setData ] = useState([]);

  const [ user, setUser ] = useState( null );
  const [ changingAccess, setChangingAccess ] = useState( null );

  const loadUsers = useCallback( async () => {
    setGetting( true );

    const { success, message, result } = await getUsers();

    if( success ) {
      setData( userTransform( result ));
    }

    setGetting( false );

    return { success, message };
  }, []);

  const handleChangeAccess = useCallback( async ( id ) => {
    setChangingAccess( id );

    const { success, message } = await changeUserAccess( id );

    setChangingAccess( null );

    if( success )
      loadUsers();

    return { success, message };
  }, []);

  const handleOpenModal = useCallback(( data ) => {
    setUser( data );

    setIsOpenModal( true );
  }, []);

  const handleCloseModal = useCallback(() => {
    setUser( null );

    setIsOpenModal( false );
  }, []);

  const handleSave = useCallback( async ( name, username, password, type ) => {
    setSaving( true );

    const { success, message } = user?._id
      ? user?.function === 'Excluir'
        ? await deleteUser( user._id )
        : await updateUser( user._id, name, username, password, type )
      : await createUser( name, username, password, type );

    setSaving( false );

    if( success ) loadUsers();

    return { success, message };
  }, [ user ]);

  return useMemo(() => ({
    data,
    user,
    getting,
    saving,
    changingAccess,
    isOpenModal,
    loadUsers,
    handleOpenModal,
    handleCloseModal,
    handleSave,
    handleChangeAccess,
  }), [
    data,
    user,
    getting,
    saving,
    changingAccess,
    isOpenModal,
    loadUsers,
    handleOpenModal,
    handleCloseModal,
    handleSave,
    handleChangeAccess,
  ]);
};
