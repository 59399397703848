import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  Button,
  TextField,
  Modal,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';

import {
  DropzoneArea,
} from 'material-ui-dropzone';

import {
  useStore,
} from '../../store';

import {
  LoadingButton,
  Image,
} from '../index';

import useStyles from './styles';

export function ModalPost() {
  const Styles = useStyles();

  const {
    alert,
    post,
  } = useStore();

  const [ name, setName ] = useState('');
  const [ image, setImage ] = useState( null );

  const handleSave = useCallback( async () => {
    const formData = new FormData();

    formData.append('name', name );
    formData.append('file', image );

    const { success, message } = await post.handleSave( formData );

    alert.openAlert( success, message );

    if( success)
      post.handleCloseModal();
  }, [ name, image ]);

  useEffect(() => {
    if( !post.isOpenModal ) {
      setName('');
    } else {
      if( post.post ) {
        setName( post.post.name );
      }
    }
  }, [ post.isOpenModal, post.post ]);

  return (
    <Modal
      open={ post.isOpenModal }
      onClose={ post.handleCloseModal }
      className={ Styles.modal }
    >
      <Paper className={ Styles.modalView }>
        <Toolbar className={ Styles.modalToolbar }>
          <Typography
            noWrap
            className={ Styles.modalTitle }
            variant="h6"
          >
            {`${ post.post?.function || 'Adicionar'} Postagem`}
          </Typography>
        </Toolbar>

        <Container className={ Styles.bodyModal }>
          {
            post.post?.function === 'Excluir'
              ? <>
                  <Typography className={ Styles.question } component="h5">
                    Deseja realmente excluir a postagem
                  </Typography>

                  <Typography component="h5">
                    { post.post?.name }?
                  </Typography>
                </>
              : <>
                  <TextField
                    className={ Styles.input }
                    label="Nome"
                    placeholder="Nome"
                    variant="outlined"
                    value={ name }
                    onChange={ event => setName( event.target.value )}
                  />

                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    className={ Styles.input }
                    dropzoneText={"Imagem da postagem, arraste ou clique"}
                    onChange={( files ) => setImage( files[ 0 ])}
                    showPreviews={ true }
                    showPreviewsInDropzone={ false }
                    previewText="Imagem selecionada"
                    showAlerts={ false }
                    filesLimit={ 1 }
                  />
                </>
          }

        {
          post.post?.file &&
            <div className={ Styles.currentImage }>
              <span>Background Atual:</span>

              <Image
                image={ post.post?.file }
              />
            </div>
        }

          <Container className={ Styles.modalActions }>
            <Button
              variant="outlined"
              color="inherit"
              onClick={ post.handleCloseModal }
            >
              { post.post?.function === 'Excluir' ? 'Não' : 'Cancelar'}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={ handleSave }
              disabled={ post.post?.function !== 'Excluir' &&
                (post.saving ||
                !name ||
                (
                  name === post.post?.name &&
                  !image
                ))
              }
            >
              { post.post?.function === 'Excluir' ? 'Sim' : 'Salvar'}

              { post.saving && <LoadingButton />}
            </Button>
          </Container>
        </Container>
      </Paper>
    </Modal>
  );
};
