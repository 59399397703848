import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({
  SwitchView: {
    display: 'flex',
    flexDirection: 'column',
    width: '85%',
    paddingTop: 8,
    paddingBottom: 8,
  },

  SwitchLabel: {
    fontSize: '1rem',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.54)',
  },

  SwitchButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 64,
    height: 32,
    borderRadius: 32,
    border: 'none',
  },

  SwitchCircle: {
    content: '""',
    width: 24,
    height: 24,
    borderRadius: 24,
  },

  exist: {
    fontSize: '0.75rem',
    fontWeight: 500,
    marginTop: 8,
    color: theme.palette.primary.dark,
  },
}));

export default useStyles;
