import React from 'react';

import {
  Snackbar,
} from '@material-ui/core';

import {
  Alert as MUIAlert,
} from '@material-ui/lab';

import {
  useStore,
} from '../../store';

import useStyles from './styles';

export function Alert() {
  const Styles = useStyles();

  const {
    alert,
  } = useStore();

  return (
    <Snackbar
      open={ alert.open }
      autoHideDuration={ alert.fourSeconds }
      onClose={ alert.closeAlert }
      anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
      className={ Styles.alert }
    >
      <MUIAlert
        severity={ alert.success }
        onClose={ alert.closeAlert }
      >
        { alert.message }
      </MUIAlert>
    </Snackbar>
  );
};
