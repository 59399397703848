import { makeStyles } from '@material-ui/core/styles';

const appBarHeight = 85;

const useStyles = makeStyles( theme => ({
  alert: {
    marginTop: appBarHeight,
  },
}));

export default useStyles;
