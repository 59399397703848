import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use( config => {
  const user = JSON.parse( localStorage.getItem('InfoTurboTelecom@AuthenticatedUser'));

  if( user )
    config.headers.common['Authorization'] = `Bearer ${ user.token }`;

  return config;
}, ( err ) => {
  return Promise.reject( err );
});

export default api;
