import React, {
    useEffect,
  } from 'react';

  import {
    useLocation,
  } from 'react-router-dom';

  import {
    useStore,
  } from '../../store';

  import {
    installationColumns,
  } from '../../utils/columns';

  import {
    DataTable,
  } from '../../components';

  export function Installation() {
    const location = useLocation();

    const {
      installation,
      config,
    } = useStore();

    useEffect(() => {
      if( location.pathname === '/installations')
        installation.loadInstallations(config.data);

    }, [ location ]);

    return (
      <DataTable
        title="Solicitações de Instalação"
        columns={ installationColumns }
        store={ installation }
      />
    );
  };
