import api from '../config/api';

export async function getPlans() {
  try {
    const { data } = await api.get('/plan');

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};

export async function createPlan( name, description, type, value, bestSeller, cities, benefits, servicesIncluded ) {
  try {
    const { data } = await api.post('/plan', {
      name,
      description,
      type,
      value,
      bestSeller,
      cities,
      benefits,
      servicesIncluded,
    });

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};

export async function updatePlan( id, name, description, type, value, bestSeller, cities, benefits, servicesIncluded ) {
  try {
    const { data } = await api.put(`/plan/${ id }`, {
      name,
      description,
      type,
      value,
      bestSeller,
      cities,
      benefits,
      servicesIncluded,
    });

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};

export async function deletePlan( id ) {
  try {
    const { data } = await api.delete(`/plan/${ id }`);

    return data;
  } catch( err ) {
    if( err.response ) {
      const { data } = err.response;

      return {
        success: false,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: 'Falha ao se comunicar com o servidor!',
      };
    }
  }
};
