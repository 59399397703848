import {
  useState,
  useCallback,
  useMemo,
} from 'react';

import {
  getPosts,
  createPost,
  updatePost,
  deletePost,
} from '../../services';

import {
  postTransform,
} from '../../utils/helpers/transformData';

export function usePost() {
  const [ getting, setGetting ] = useState( false );
  const [ saving, setSaving ] = useState( false );
  const [ isOpenModal, setIsOpenModal ] = useState( false );

  const [ data, setData ] = useState([]);
  const [ post, setPost ] = useState( null );

  const loadPosts = useCallback( async () => {
    setGetting( true );

    const { success, message, result } = await getPosts();

    if( success ) {
      setData( postTransform( result ));
    }

    setGetting( false );

    return { success, message };
  }, []);

  const handleOpenModal = useCallback(( data ) => {
    setPost( data );

    setIsOpenModal( true );
  }, []);

  const handleCloseModal = useCallback(() => {
    setPost( null );

    setIsOpenModal( false );
  }, []);

  const handleSave = useCallback( async ( formData ) => {
    setSaving( true );

    const { success, message } = post?._id
      ? post?.function === 'Excluir'
        ? await deletePost( post._id )
        : await updatePost( post._id, formData )
      : await createPost( formData );

    setSaving( false );

    if( success ) loadPosts();

    return { success, message };
  }, [ post ]);

  return useMemo(() => ({
    data,
    post,
    getting,
    saving,
    isOpenModal,
    loadPosts,
    handleOpenModal,
    handleCloseModal,
    handleSave,
  }), [
    data,
    post,
    getting,
    saving,
    isOpenModal,
    loadPosts,
    handleOpenModal,
    handleCloseModal,
    handleSave,
  ]);
};
