import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalView: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '75%',
    maxWidth: 575,
    backgroundColor: theme.palette.background.default,
  },

  modalToolbar: {
    paddingRight: theme.spacing( 2 ),
    paddingLeft: theme.spacing( 2 ),
    paddingTop: theme.spacing( 1 ),
    paddingBottom: theme.spacing( 1 ),
    backgroundColor: theme.palette.primary.main,
    minHeight: 40
  },

  modalTitle: {
    flex: '1 1 100%',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.fontSize * 1.25
  },

  bodyModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: 15,
  },

  autoField: {
    position: 'relative',
    padding: 0,
    borderRadius: 6,
  },

  input: {
    width: '85%',
    marginTop: 10,
    marginBottom: 10,
  },

  checkboxLabel: {
    width: '85%',
    marginTop: 8,
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },

  checkboxView: {
    display: 'grid',
    gridTemplateColumns: 'repeat( 2, 1fr )',
    width: '85%',
    padding: 4,
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 8,
    borderRadius: 4,
    border: 'solid 1px rgba(0, 0, 0, 0.2)'
  },

  question: {
    marginTop: 25,
    marginBottom: 5,
  },

  checkboxView: {
    display: 'grid',
    gridTemplateColumns: 'repeat( 2, 1fr )',
    width: '85%',
    marginTop: 10,
    marginBottom: 10,
  },

  modalActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    marginTop: 25,
    width: '85%',
    color: theme.palette.error.main,
  },
}));

export default useStyles;
