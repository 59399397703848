import {
  useState,
  useCallback,
  useMemo,
} from 'react';

import {
  getBenefits,
  createBenefit,
  updateBenefit,
  deleteBenefit,
} from '../../services';

import {
  benefitTransform,
} from '../../utils/helpers/transformData';

export function useBenefit() {
  const [ getting, setGetting ] = useState( false );
  const [ saving, setSaving ] = useState( false );
  const [ isOpenModal, setIsOpenModal ] = useState( false );

  const [ data, setData ] = useState([]);
  const [ benefit, setBenefit ] = useState( null );

  const loadBenefits = useCallback( async () => {
    setGetting( true );

    const { success, message, result } = await getBenefits();

    if( success ) {
      setData( benefitTransform( result ));
    }

    setGetting( false );

    return { success, message };
  }, []);

  const handleOpenModal = useCallback(( data ) => {
    setBenefit( data );

    setIsOpenModal( true );
  }, []);

  const handleCloseModal = useCallback(() => {
    setBenefit( null );

    setIsOpenModal( false );
  }, []);

  const handleSave = useCallback( async ( name, description, radio, fiber ) => {
    setSaving( true );

    const { success, message } = benefit?._id
      ? benefit?.function === 'Excluir'
        ? await deleteBenefit( benefit._id )
        : await updateBenefit( benefit._id, name, description, radio, fiber )
      : await createBenefit( name, description, radio, fiber );

    setSaving( false );

    if( success ) loadBenefits();

    return { success, message };
  }, [ benefit ]);

  return useMemo(() => ({
    data,
    benefit,
    getting,
    saving,
    isOpenModal,
    loadBenefits,
    handleOpenModal,
    handleCloseModal,
    handleSave,
  }), [
    data,
    benefit,
    getting,
    saving,
    isOpenModal,
    loadBenefits,
    handleOpenModal,
    handleCloseModal,
    handleSave,
  ]);
};
