import React from 'react';

import useStyles from './styles';

export function Image({ image }) {
  const Styles = useStyles();

  return (
    <img
      src={`${ process.env.REACT_APP_API_URL }/image/${ image }`}
      alt="image"
      className={ Styles.img }
    />
  );
};
